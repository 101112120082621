<template>
  <div :class="$style.listWrap">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :locale="emptyLocale"
      @change="handlePagination"
    >
      <template slot="createDate" slot-scope="text">
        {{ formatTime(text) }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import { listBind } from '@/services/iot-electric-box/terminal-manage';
@Component({
  components: {
    ConditionSearch,
  },
})
export default class BindRecord extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  text = '开发中';
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  params = {
    pageSize: 10,
    pageNum: 1,
    deviceId: '',
    tenantId: '',
    buildingId: '',
    endTime: '',
    startTime: '',
  };
  tableData = [];
  options = [
    {
      label: '时间',
      model: ['startTime', 'endTime'],
      type: 5,
    },
  ];
  created() {
    this.params.tenantId = this.deviceInfo.tenantId;
    this.params.deviceId = this.deviceInfo.deviceId;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.getList();
  }
  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.tenantId = this.deviceInfo.tenantId;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.pagination.current = 1;
    await this.getList();
  }
  mounted() {}
  async getList() {
    if (!this.params.buildingId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    let res = await listBind(this.params);
    this.pagination.total = res.total;
    this.tableData = res.records;
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        width: 180,
        align: 'left',
        title: '绑定时间',
        dataIndex: 'createDate',
        scopedSlots: { customRender: 'createDate' },
      },
      {
        width: 120,
        align: 'left',
        title: '类型',
        dataIndex: 'isBind',
        customRender: value => (value ? '绑定' : '解绑'),
      },
      {
        width: 180,
        align: 'left',
        title: '操作人',
        dataIndex: 'createBy',
        customRender: value => value || '管理员',
      },
      {
        title: '操作设备信息',
        align: 'left',
        dataIndex: 'operatingDeviceInfo',
      },
    ];
  }
  handleSearchSubmit(data) {
    for (let key in data) {
      this.params[key] = data[key];
    }
    this.pagination.current = 1;
    this.getList();
  }
  handleReset() {}
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0;
  position: relative;

  :global {
    .searchBtns {
      text-align: right;
    }
  }
}
</style>
