<template>
  <div :class="$style.detail">
    <div class="feedBackBtn" @click="showFeedback">
      <x-icon type="tc-icon-warning" />{{ $t('electricBox.mg.feedback') }}
    </div>
    <div :class="$style.info">
      <a-form layout="inline">
        <a-form-item :label="$t('electricBox.mg.bindProject')">
          <a-select style="width: 450px" v-model="params.projectId">
            <a-select-option
              :value="item.pkProjectId"
              v-for="item in projectList"
              :key="item.pkProjectId"
              >{{
                ((item.isCurrent && '（当前）') || '') + item.projectName
              }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('electricBox.mg.bindBuilding')">
          <a-select style="width: 140px" v-model="params.buildingId">
            <a-select-option
              :value="item.pkBuildingId"
              v-for="item in buildList"
              :key="item.pkBuildingId"
              >{{
                ((item.isCurrent && '（当前）') || '') + item.buildingName
              }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>

      <a-descriptions :column="2" :colon="true">
        <a-descriptions-item :label="$t('electricBox.mg.projectLeader')">
          <span :title="deviceInfo.projectManagers">{{
            deviceInfo.projectManagers
          }}</span>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('electricBox.mg.expirationTime')">
          {{
            (deviceInfo.expireDate && formatTime(deviceInfo.expireDate)) || ''
          }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :column="1" :colon="true">
        <a-descriptions-item :label="$t('electricBox.mg.projectAddress')">
          <span :title="deviceInfo.projectAddress">{{
            deviceInfo.projectAddress
          }}</span>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div :class="$style.contentBox">
      <a-tabs
        v-if="params.deviceId"
        :default-active-key="activeKey"
        @change="changeTab"
      >
        <a-tab-pane key="1" :tab="$t('electricBox.mg.deviceEvents')"
          ><faultEvents v-if="activeKey === '1'" :deviceInfo="params" />
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('electricBox.mg.operationApproval')"
          ><operateAuth v-if="activeKey === '2'" :deviceInfo="params"
        /></a-tab-pane>
        <a-tab-pane key="3" :tab="$t('electricBox.mg.liftedReport')"
          ><liftReport
            v-if="activeKey === '3'"
            :deviceInfo="params"
            :deviceName="data.deviceName"
        /></a-tab-pane>
        <!-- <a-tab-pane key="4" tab="绑定记录">
          <bindRecord v-if="activeKey === '4'" :deviceInfo="params" />
        </a-tab-pane> -->
        <a-tab-pane key="4" :tab="$t('electricBox.mg.configurationLog')">
          <configLog
            v-if="activeKey === '4'"
            :deviceInfo="params"
            :deviceName="data.deviceName"
          />
        </a-tab-pane>
        <a-tab-pane key="5" :tab="$t('electricBox.mg.safeNotice')">
          <safetyNotice
            v-if="activeKey === '5'"
            :deviceInfo="params"
            :deviceName="data.deviceName"
            :deviceData="deviceInfo"
          />
        </a-tab-pane>
        <a-tab-pane
          key="6"
          :tab="$t('electricBox.mg.firmwareInformation')"
          v-if="data.version !== 'V1'"
        >
          <hardWare v-if="activeKey === '6'" :deviceName="data.deviceName" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import faultEvents from './components/fault-events.vue';
import safetyNotice from './components/safety-notice.vue';
import operateAuth from './components/operate-auth.vue';
import bindRecord from './components/bind-record.vue';
import liftReport from './components/lift-report.vue';
import configLog from './components/config-log.vue';
import Qrcode from '../../general-view/safe-operation/qrcode.vue';
import HardWare from './components/hardware.vue';
import { createModal } from '@triascloud/x-components';
import {
  getBindProject,
  getBindBuilding,
  getDeviceInfo,
} from '@/services/iot-electric-box/terminal-manage';

@Component({
  components: {
    faultEvents,
    safetyNotice,
    operateAuth,
    bindRecord,
    liftReport,
    configLog,
    Qrcode,
    HardWare,
  },
})
export default class DeviceManage extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Function, default: function() {} }) close;
  tenantList = [];
  projectList = [];
  buildList = [];
  deviceInfo = {};
  formatTime(t) {
    if (t) {
      return this.$moment(t).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  params = {
    deviceId: '',
    tenantId: '',
    projectId: undefined,
    buildingId: undefined,
  };

  async mounted() {
    this.params.deviceId = this.data.id;
    await this.getDeviceInfo(this.params);
    this.params.tenantId = this.deviceInfo.idxTenantId;
    this.params.projectId = this.deviceInfo.projectId;
    this.params.buildingId = this.deviceInfo.buildId;
    this.getBindProjects({
      deviceId: this.params.deviceId,
      tenantId: this.params.tenantId,
    });
  }

  @Watch('params.projectId')
  async handleProjectIdChange(nVal) {
    if (!nVal) {
      return;
    }
    await this.getBindBuildings(this.params);
    if (this.buildList.length > 0) {
      // 查看的设备，有可能绑定多个楼栋，且不是第一个楼栋id，导致buildingId更改了
      // this.params.buildingId = this.buildList[0].pkBuildingId;
    } else {
      this.params.buildingId = '';
      this.deviceInfo = {};
    }
  }
  @Watch('params.buildingId')
  async handleBuildingIdChange(nVal, oVal) {
    if (!nVal) {
      return;
    }
    if (oVal !== undefined) {
      await this.getDeviceInfo(this.params);
    }
  }

  activeKey = '1';
  changeTab(activeKey) {
    this.activeKey = activeKey;
    this.detailTenantId = '';
  }
  handleTenantChange(id) {
    this.detailTenantId = id;
  }
  showFeedback() {
    createModal(
      () => (
        <Qrcode
          src={'https://app.triascloud.com/link/1432517985899192321'}
          clickable={true}
        >
          <div slot="desc">{this.$t('electricBox.mg.qrFeeback')}</div>
        </Qrcode>
      ),
      {
        title: this.$t('electricBox.mg.feedback'),
        width: `300px`,
      },
    );
  }
  async getBindProjects(params) {
    this.projectList = await getBindProject(params);
  }
  async getBindBuildings(params) {
    this.buildList = await getBindBuilding(params);
  }
  async getDeviceInfo(params) {
    this.deviceInfo = await getDeviceInfo(params);
    let members = [];
    this.deviceInfo.projectManager.forEach(item => {
      members.push(item.memberName);
    });
    this.deviceInfo.projectManagers = members.join('、');
  }
}
</script>

<style lang="less" module>
:global {
  .condition-item-wapper {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    .ant-form-item {
      margin-right: 0;
      margin-bottom: 5px !important;
      .ant-form-item-label > label:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
}
.detail {
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  .info {
    padding: 20px 20px 10px;
    border-radius: 6px;
    box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);
    margin-bottom: 15px;
    .infoItem {
      padding: 15px 0;
    }
    :global {
      .ant-form-inline {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .ant-form-item {
          margin-right: 0;
          margin-bottom: 12px;
          .ant-form-item-label > label:after {
            content: ':';
            position: relative;
            top: -0.5px;
            margin: 0 8px 0 2px;
          }
        }
      }
      .ant-descriptions-item-label {
        color: var(--font-info);
      }
      .ant-descriptions-item:first-child {
        display: flex;
        .ant-descriptions-item-content {
          flex: 1;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .ant-descriptions-row .ant-descriptions-item:nth-child(2) {
        text-align: right;
        .ant-descriptions-item-content {
          min-width: 126px;
        }
      }
    }
  }

  .contentBox {
    .boxTitle {
      font-size: 14px;
      color: var(--font);
      padding: 0 20px 10px;
    }
  }

  .buttonGroup {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 70px;
    line-height: 70px;
    border-top: 1px solid var(--tag-border);
    background: var(--block-bg);
    z-index: 99;
    box-sizing: content-box;
    :global(.ant-btn) {
      margin-left: 20px;
    }
  }
}
</style>
