<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="提升日期" prop="defaultTime">
        <a-date-picker
          v-model="form.defaultTime"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD HH:mm:ss"
          show-time
        />
        <div style="color:red">
          提示：录入日期必须在相邻楼层的时间段内，通常情况下安全防护平台每天提升一次，请合理添加数据！
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getAllBuidingInfo,
  editBuidingInfo,
} from '@/services/iot-electric-box/terminal-manage';

@Component({})
export default class EditPromote extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) buildingId;

  form = {
    defaultTime: this.$moment(),
  };
  rules = {
    defaultTime: [{ required: true, message: '请选择提升日期' }],
  };
  floor = null;
  @Watch('editData', { immediate: true })
  dataChanged(newVal) {
    if (newVal) {
      this.form.defaultTime = this.$moment(this.editData.promoteDate);
      this.floor = this.editData.currentLayer;
    }
  }
  mounted() {
    this.getTableData();
  }
  timeData = [];
  async getTableData() {
    const params = {
      climbId: this.buildingId,
    };
    const data = await getAllBuidingInfo(params);
    this.timeData = data.map(item => {
      return {
        id: item.currentLayer,
        date: this.formatTimeToMoment(item.promoteDate),
      };
    });
  }
  formatTimeToMoment(t) {
    const time = Number(t);
    if (t === '' || t === undefined || t === null) return;
    const tStr = this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    return this.$moment(tStr, 'YYYY-MM-DD HH:mm:ss');
  }
  // 判断提升楼栋数据的逻辑
  // 1、 4 5 6   5之后插入   5的时间大于5的这一天结束，小于6的这一天开始
  // 2、 4 5 6   6之后插入   6的时间大于 6的这天的结束即可
  disabledStartDate(value) {
    const idxFloor = this.floor;
    const idx = this.timeData.findIndex(item => item.id === idxFloor);
    const currentFloor = this.timeData[idx];
    const startT = currentFloor.date.endOf('day').valueOf();
    const lastFloor = this.timeData[0];
    if (currentFloor.id < lastFloor.id) {
      const endT = this.timeData[idx - 1].date.startOf('day').valueOf();
      return value.valueOf() <= startT || value.valueOf() >= endT;
    }
    if (currentFloor.id === lastFloor.id) {
      return value.valueOf() <= lastFloor.date.endOf('day').valueOf();
    }
  }

  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const params = {
              climbId: this.buildingId,
              climbMessageId: this.editData.id,
              liftType: 'RISE',
              promoteDate: this.form.defaultTime.valueOf(),
            };
            await editBuidingInfo(params);
            this.$message.success('编辑成功');
            resolve(true);
          } catch (error) {
            reject(error);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style scoped></style>
