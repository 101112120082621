<template>
  <div :class="$style.listWrap">
    <div :class="$style.title">
      <span :class="$style.marginLeft5">楼栋提升数据</span>
      <a-popconfirm
        :visible="riseVisible"
        title="还未绑定项目，不允许添加数据！"
        @confirm="riseVisible = false"
        @cancel="riseVisible = false"
      >
        <a-icon
          v-if="inputDisabled && deviceItem.operational"
          :class="$style.edit"
          @click="setFloor"
          type="edit"
        />
      </a-popconfirm>
      <span v-if="!inputDisabled">
        <a-button
          size="small"
          :class="$style.save"
          @click="saveFloor"
          type="primary"
        >
          确定
        </a-button>
        <a-button size="small" @click="getBuildInfo">
          取消
        </a-button>
      </span>
    </div>
    <div :class="$style.card">
      <div>
        <span style="margin-right: 10px">首次提升所在层数</span>
        <a-input-number
          v-model="form.floor"
          :disabled="inputDisabled"
          :min="0"
          :step="1"
        />
      </div>
      <div>
        <span style="margin-right: 10px">首次提升所在高度</span>
        <a-input-number
          v-model="form.height"
          :disabled="inputDisabled"
          :min="0"
          :step="1"
        />
      </div>
      <div>
        <span style="margin-right: 10px">楼层层高</span>
        <a-input-number
          v-model="form.floorHeight"
          :disabled="inputDisabled"
          :min="0"
          :step="1"
        />
      </div>
    </div>
    <div :class="$style.title" style="margin-bottom: 10px">
      <span :class="$style.marginLeft5">当前楼栋数据</span>
      <a-popconfirm
        :visible="currentVisible"
        title="还未绑定项目，不允许添加数据！"
        @confirm="currentVisible = false"
        @cancel="currentVisible = false"
      >
        <a-icon
          v-if="deviceItem.operational"
          type="plus"
          :class="$style.edit"
          @click="addPromote"
        />
      </a-popconfirm>
    </div>
    <div style="height: 450px">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.id"
        :locale="emptyLocale"
        @change="handlePagination"
        :scroll="{ y: 420 }"
      >
        <template slot="currentLayer" slot-scope="text, record">
          {{ formatFloor(record) }}
        </template>
        <template slot="promoteDate" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="liftType" slot-scope="text, record">
          {{ formatType(record) }}
        </template>
        <template slot="action" slot-scope="record">
          <span :class="$style.buttonGroups" @click.stop>
            <a
              v-if="record.promoteType !== 'SYSTEM' && deviceItem.operational"
              :class="[$style.button]"
              @click.stop="editData(record)"
              >修改</a
            >
            <a-popconfirm
              placement="topRight"
              title="确认删除此条人工核验数据？删除后在此之后的层高数据都会相应调整，确认删除？"
              @confirm="deleteData(record)"
            >
              <a
                v-if="record.promoteType === 'MANUAL' && deviceItem.operational"
                :class="[$style.button]"
                >删除</a
              >
            </a-popconfirm>
          </span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Empty from '@/components/empty-content';
import { createFormModal, createModal } from '@triascloud/x-components';
import EditPromote from './edit-promote';
import AddPromote from './tips-add';
import {
  getBuildingInfo,
  setBuildingInfo,
  getAllBuidingInfo,
  deleteBuidingInfo,
} from '@/services/iot-electric-box/terminal-manage';

@Component({})
export default class Promote extends Vue {
  @Prop({ type: String, default: '' }) buildingId;
  @Prop({ type: String, default: '' }) netStatus;
  @Prop({ type: Object, default: () => {} }) deviceItem;

  form = {
    floor: null,
    height: null,
    floorHeight: null,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  inputDisabled = true;
  riseVisible = false;
  setFloor() {
    const bind = ['UN_ASSIGNED', 'UN_BIND'].indexOf(this.netStatus) > -1;
    if (bind) {
      this.riseVisible = true;
    } else {
      this.inputDisabled = false;
    }
  }
  async saveFloor() {
    if (
      this.form.floor === null ||
      this.form.height === null ||
      this.form.floorHeight === null
    ) {
      this.$message.warning('请填入数值');
      return;
    }
    const params = {
      climbId: this.buildingId,
      firstRiseFloor: this.form.floor,
      firstRiseFloorHeight: this.form.height,
      floorHeight: this.form.floorHeight,
    };
    try {
      await setBuildingInfo(params);
      this.$message.success('保存成功');
    } catch {
      return false;
    } finally {
      await this.getBuildInfo();
      this.inputDisabled = true;
    }
  }
  mounted() {
    this.getBuildInfo();
    this.getAllBuidingInfos();
  }
  async getBuildInfo() {
    if (!this.buildingId) return;
    const result = await getBuildingInfo(this.buildingId);
    this.form.floor = result.firstRiseFloor;
    this.form.height = result.firstRiseFloorHeight;
    this.form.floorHeight = result.floorHeight;
    this.inputDisabled = true;
  }
  tableData = [];
  async getAllBuidingInfos() {
    const params = {
      climbId: this.buildingId,
    };
    if (!params.climbId) return;
    this.tableData = await getAllBuidingInfo(params);
    this.tableData.map(item => {
      item.id === '' ? (item.id = -1) : item.id;
    });
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  formatFloor(row) {
    const floor = `第${row.currentLayer}层`;
    const height = `（${row.currentHeight}米）`;
    return `${floor}${height}`;
  }
  formatType(row) {
    const typeObj = {
      RISE: '提升',
      DROP: '下降',
    };
    const operationObj = {
      NORMAL: '自动',
      MANUAL: '手动',
      SYSTEM: '系统',
      SYSTEM_ADMIN: '系统管理员',
    };
    const type = `${typeObj[row.liftType]}`;
    const op = `${operationObj[row.promoteType]}`;
    return `${type}（${op}）`;
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        // fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '层数/层高',
        width: 160,
        dataIndex: 'currentLayer',
        scopedSlots: { customRender: 'currentLayer' },
      },
      {
        align: 'left',
        title: '时间',
        width: 200,
        dataIndex: 'promoteDate',
        scopedSlots: { customRender: 'promoteDate' },
      },
      {
        align: 'left',
        title: '类型/操作',
        width: 130,
        dataIndex: 'liftType',
        scopedSlots: { customRender: 'liftType' },
      },
      {
        title: '操作',
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  handlePagination(data) {
    this.pagination = data;
    // this.getDataList();
  }
  currentVisible = false;
  addPromote() {
    const flag =
      ['UN_ASSIGNED', 'UN_BIND', 'BIND'].indexOf(this.netStatus) > -1;
    if (flag) {
      this.currentVisible = true;
    } else {
      const model = createModal(
        () => (
          <AddPromote
            buildingId={this.buildingId}
            onClose={() => model.handleClose()}
            onRefreshList={() => this.getAllBuidingInfos()}
          />
        ),
        {
          title: '重要提示',
          width: 450,
          height: 400,
        },
      );
    }
  }
  async editData(item) {
    try {
      const result = await createFormModal(
        () => <EditPromote buildingId={this.buildingId} editData={item} />,
        {
          width: '442px',
          title: '编辑楼栋数据',
        },
      );
      if (result) {
        await this.getAllBuidingInfos();
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  async deleteData(row) {
    try {
      await deleteBuidingInfo(row.id);
      this.$message.success('删除成功');
      await this.getAllBuidingInfos();
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0 20px;
  height: calc(100vh - 301px);
  position: relative;
  .marginLeft5 {
    margin-left: 5px;
  }
  .title {
    margin: 10px 16px 0;
    border-left: 4px solid var(--primary);
    height: 24px;
    display: flex;
    align-items: center;
    .edit {
      margin-left: 10px;
      color: var(--primary);
      font-size: 18px;
    }
    .save {
      margin: 0 10px;
    }
  }
  .card {
    //border-radius: 6px;
    //box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-left: 16px;
    align-items: center;
  }
  .buttonGroups {
    display: flex;
    justify-content: flex-start;
    .button {
      width: 35px;
    }
  }
}
</style>
