<template>
  <div :class="$style.listWrap">
    <condition-search
      :options="options"
      @custom-change="customChange"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.id"
      :locale="emptyLocale"
      @change="handlePagination"
    >
      <template slot="time" slot-scope="text">
        {{ formatTime(text) }}
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import { listAlarmMessage } from '@/services/iot-electric-box/terminal-manage';
import {
  errorList,
  warnList,
  eventKey,
} from '@/views/iot/general-view/safe-operation/event';
import { deepClone } from '@triascloud/utils';
@Component({
  components: {
    ConditionSearch,
  },
})
export default class FaultEvents extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  text = '开发中';
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  params = {
    pageSize: 10,
    pageNum: 1,
    deviceId: '',
    buildingId: '',
    endTime: '',
    startTime: '',
    warnOrError: 'ERROR',
    sensorType: '',
  };
  tableData = [];
  options = [];
  created() {
    this.options = [
      {
        label: this.$t('electricBox.mg.eventType'),
        type: 6,
        children: [
          {
            model: 'warnOrError',
            items: [
              {
                label: this.$t('generalView.key.fault'),
                value: 'ERROR',
              },
              {
                label: this.$t('generalView.key.warning'),
                value: 'WARN',
              },
            ],
          },
          {
            model: 'sensorType',
            items: errorList,
          },
        ],
        width: '250px',
      },
      {
        label: this.$t('generalView.table.time'),
        model: ['startTime', 'endTime'],
        type: 5,
      },
    ];
    this.params.deviceId = this.deviceInfo.deviceId;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.getList();
  }
  customChange(option) {
    switch (option.model) {
      case 'warnOrError':
        this.eventChange(option.value);
        break;
    }
  }
  eventChange(value) {
    if (value === 'ERROR') {
      this.options[0].children[1].items = errorList;
    } else if (value === 'WARN') {
      this.options[0].children[1].items = warnList;
    } else {
      this.options[0].children[1].items = [
        {
          label: '全部',
          value: '',
        },
      ];
    }
  }

  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.buildingId = this.deviceInfo.buildingId;
    this.pagination.current = 1;
    await this.getList();
  }
  mounted() {}
  async getList() {
    if (!this.params.buildingId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    const params = deepClone(this.params);
    if (!params.warnOrError) {
      params.warnOrError = null;
    }
    if (!params.sensorType) {
      params.sensorType = null;
    }
    let res = await listAlarmMessage(params);
    this.pagination.total = res.total;
    this.tableData = res.records;
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('electricBox.mg.eventType'),
        width: 150,
        dataIndex: 'sensorType',
        customRender: val => {
          return eventKey[val];
        },
      },
      {
        align: 'left',
        title: this.$t('generalView.table.time'),
        width: 180,
        dataIndex: 'time',
        scopedSlots: { customRender: 'time' },
        // customRender: records =>
        //   this.$moment(records.time).format('YYYY-MM-DD'),
      },
      {
        align: 'left',
        title: this.$t('electricBox.mg.eventContent'),
        dataIndex: 'content',
      },
    ];
  }
  handleSearchSubmit(data) {
    for (let key in data) {
      if (data[key]) {
        this.params[key] = data[key];
      } else {
        delete this.params[key];
      }
    }
    this.pagination.current = 1;
    this.getList();
  }
  handleReset() {}
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0;
  position: relative;

  :global {
    .searchBtns {
      text-align: right;
    }
  }
}
</style>
