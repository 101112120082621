<template>
  <div>
    <div v-if="tipsShow">
      <div :class="$style.tips">
        1、楼栋数据因安监终端联网状态可能有延迟，添加数据前请再三核对！
      </div>
      <div :class="$style.tips">
        2、当您添加提升楼层后，系统会对比您的添加时间和安监终端上报的提升时间，若上报的时间在您的添加记录之前，则上报记录不会做入库处理！
      </div>
      <div :class="$style.tips">
        <span style="color:red">
          3、您添加/删除的数据会影响到当前安监终端绑定楼栋的所有提升数据，请谨慎操作！
        </span>
      </div>
    </div>
    <div v-else>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-form-model-item label="添加到此层后" prop="floor">
          <div>
            <a-select v-model="form.floor" style="width: 195px">
              <a-select-option
                v-for="item in timeData"
                :key="item.id"
                :value="item.id"
              >
                {{ item.id }}
              </a-select-option>
            </a-select>
          </div>
          <div style="color:red;margin: 10px 0 15px">
            <span>数据将会被添加为</span>
            <span>第{{ form.floor + 1 }}层</span>
            <span>的提升数据</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="提升日期" prop="time">
          <div>
            <a-date-picker
              v-model="form.time"
              :disabled-date="disabledStartDate"
              format="YYYY-MM-DD HH:mm:ss"
              show-time
            />
          </div>
          <div style="color:red;margin: 10px 0 15px">
            提示：录入日期必须在相邻楼层的时间段内，通常情况下安全防护平台每天提升一次，请合理添加数据！
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div style="text-align:end">
      <a-button style="margin-right: 15px" @click="cancleModal">取消</a-button>
      <a-button
        v-if="tipsShow"
        type="primary"
        :disabled="timeRun"
        @click="tipsShow = false"
      >
        {{ buttonText }}
      </a-button>
      <a-button
        v-if="!tipsShow"
        type="primary"
        :disabled="timeRun"
        @click="confirmModal"
      >
        {{ buttonText }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import {
  addBuidingInfo,
  getAllBuidingInfo,
} from '@/services/iot-electric-box/terminal-manage';
import { deepClone } from '@triascloud/utils';

@Component({})
export default class TipsAdd extends Vue {
  @Prop({ type: String, default: '' }) buildingId;

  tipsShow = false;
  timeRun = false;
  buttonText = '确定';
  seconds = 10;
  form = {
    floor: null,
    time: this.$moment(),
  };
  rules = {
    floor: [{ required: true, message: '请输入添加的楼层' }],
    time: [{ required: true, message: '请选择提升日期' }],
  };
  mounted() {
    // this.timeDown();
    this.getTableData();
  }
  timeDown() {
    if (this.timeRun) return;
    this.timeRun = true;
    this.buttonText = `确定(${this.seconds})`;
    const timmer = setInterval(() => {
      this.seconds--;
      this.buttonText = `确定(${this.seconds})`;
      if (this.seconds < 0) {
        clearInterval(timmer);
        this.buttonText = '确定';
        this.seconds = 10;
        this.timeRun = false;
      }
    }, 1000);
  }
  timeData = [];
  async getTableData() {
    const params = {
      climbId: this.buildingId,
    };
    const data = await getAllBuidingInfo(params);
    this.timeData = data.map(item => {
      return {
        id: item.currentLayer,
        date: this.formatTimeToMoment(item.promoteDate),
      };
    });
    this.form.floor = this.timeData[0].id;
    const cloneData = deepClone(this.timeData);
    this.form.time = cloneData[0].date.add(1, 'days');
  }
  // 判断提升楼栋数据的逻辑
  // 1、 4 5 6   5之后插入   5的时间大于5的这一天结束，小于6的这一天开始
  // 2、 4 5 6   6之后插入   6的时间大于 6的这天的结束即可
  disabledStartDate(value) {
    const idxFloor = this.form.floor;
    const idx = this.timeData.findIndex(item => item.id === idxFloor);
    const currentFloor = this.timeData[idx];
    const startT = currentFloor.date.endOf('day').valueOf();
    const lastFloor = this.timeData[0];
    if (currentFloor.id < lastFloor.id) {
      const endT = this.timeData[idx - 1].date.startOf('day').valueOf();
      return value.valueOf() <= startT || value.valueOf() >= endT;
    }
    if (currentFloor.id === lastFloor.id) {
      return value.valueOf() <= lastFloor.date.endOf('day').valueOf();
    }
  }

  formatTimeToMoment(t) {
    const time = Number(t);
    if (t === '' || t === undefined || t === null) return;
    const tStr = this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    return this.$moment(tStr, 'YYYY-MM-DD HH:mm:ss');
  }
  @Emit('close')
  cancleModal() {}

  confirmModal() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        const params = {
          climbId: this.buildingId,
          liftType: 'RISE',
          promoteDate: this.form.time.valueOf(),
        };
        await addBuidingInfo(params);
        this.$message.success('添加成功');
        this.$emit('close');
        this.$emit('refreshList');
      }
    });
  }
}
</script>

<style lang="less" module>
.tips {
  margin-bottom: 15px;
}
</style>
