<template>
  <div :class="$style.listWrap">
    <div>
      <a-radio-group v-model="radioChoose" @change="radioGroupChange">
        <div :class="$style.unify" style="height:40px">
          <div>
            <a-radio value="1" :defaultChecked="true">
              统一配置
            </a-radio>
          </div>
          <div v-if="radioChoose === '1'">
            <a-form-model layout="inline" :model="form">
              <div :class="$style.unify">
                <div>
                  <a-form-model-item prop="min">
                    <a-input-number
                      v-model="form.min"
                      :min="0"
                      :max="999"
                      :step="20"
                    />
                  </a-form-model-item>
                </div>
                <div style="margin-right: 16px">至</div>
                <div>
                  <a-form-model-item prop="max">
                    <a-input-number
                      v-model="form.max"
                      :min="0"
                      :max="1000"
                      :step="20"
                    />
                  </a-form-model-item>
                </div>
                <div style="white-space: nowrap">
                  <span>（提示：为保证设备安全，最大不超过</span>
                  <span style="color:red">1000</span>
                  <span>毫米）</span>
                </div>
              </div>
            </a-form-model>
          </div>
        </div>
        <div :class="$style.unify" style="height:40px">
          <div>
            <a-radio value="2" :disabled="circleData.length === 0">
              独立配置
            </a-radio>
          </div>
          <div v-if="radioChoose === '2'">
            <a-switch
              v-model="multipleCheck"
              @change="switchChange"
              checked-children="多选"
              un-checked-children="单选"
            />
          </div>
        </div>
      </a-radio-group>
    </div>
    <div :class="$style.circleDiv" style="width:100%">
      <div
        v-for="item in circleData"
        :key="item.cid"
        :class="[
          $style.circleNum,
          multipleCheck ? $style.numHoverChecked : $style.numHoverUnchecked,
        ]"
      >
        <div
          :class="[
            $style.circle,
            multipleCheck && item.checked ? $style.circleChecked : '',
          ]"
          @click.stop="checkCircle(item)"
        >
          {{ item.cid }}
        </div>
        <div>
          {{ item.form.min + '-' + item.form.max }}
        </div>
        <div v-if="radioChoose === '2'">
          <a-popover
            v-model="item.dialog"
            trigger="click"
            @visibleChange="popoverChange(item)"
          >
            <div slot="content">
              <a-form-model layout="inline" :model="popoverForm">
                <div style="display:flex;align-items: center">
                  <div>
                    <a-form-model-item prop="min">
                      <a-input-number
                        v-model="popoverForm.min"
                        :min="0"
                        :max="999"
                        :step="20"
                      />
                    </a-form-model-item>
                  </div>
                  <div style="margin-right: 16px">至</div>
                  <div>
                    <a-form-model-item prop="max">
                      <a-input-number
                        v-model="popoverForm.max"
                        :min="0"
                        :max="1000"
                        :step="20"
                      />
                    </a-form-model-item>
                  </div>
                  <div>毫米</div>
                </div>
                <div>
                  <span>（最大不超过</span>
                  <span style="color:red">1000</span>
                  <span>毫米）</span>
                </div>
              </a-form-model>
              <div style="text-align:end">
                <a-button
                  size="small"
                  @click="item.dialog = false"
                  style="margin-right: 10px"
                >
                  取消
                </a-button>
                <a-button
                  size="small"
                  type="primary"
                  @click.stop="submitPopover(item)"
                >
                  确定
                </a-button>
              </div>
            </div>
            <a-icon
              v-if="multipleCheck && item.checked"
              :class="[
                item.dialog ? $style.editIconHide : $style.editIconChecked,
              ]"
              type="edit"
            />
            <a-icon
              v-if="!multipleCheck"
              :class="[item.dialog ? $style.editIconHide : $style.editIcon]"
              type="edit"
            />
          </a-popover>
        </div>
      </div>
    </div>
    <div :class="$style.btn">
      <a-popconfirm
        :visible="tipsShow"
        title="当前配置中有超过200毫米的配置，可能对倾斜传感器的计算造成影响，确认保存？"
        placement="topRight"
        @confirm="confirm"
        @cancel="tipsShow = false"
      >
        <a-button type="primary" @click.stop="submit">保存</a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  deviceDisplacement,
  getDeviceDisplacement,
} from '@/services/iot-electric-box/terminal-manage';

@Component({})
export default class Displacement extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceItem;

  radioChoose = '1';
  form = {
    min: 0,
    max: 200,
  };
  visible = false;
  tipsShow = false;
  confirm() {
    this.submit();
  }
  popoverForm = {
    min: 0,
    max: 200,
  };
  multipleCheck = false;
  mounted() {
    this.getList();
  }
  circleData = [];
  async getList() {
    this.circleData = [];
    const params = {
      deviceName: this.deviceItem.deviceName,
      accessType: 'READ',
    };
    const result = await getDeviceDisplacement(params);
    if (result) {
      if (result.value) {
        const data = JSON.parse(result.value);
        this.circleData = data.map(item => {
          return {
            cid: item.cid,
            form: {
              min: item.min,
              max: item.max,
            },
            dialog: false,
            checked: false,
            edit: false,
          };
        });
      }
    } else {
      this.$message.info('待设备上报更新数据！');
    }
  }
  radioGroupChange() {
    this.form = {
      min: 0,
      max: 200,
    };
    this.multipleCheck = false;
    this.getList();
  }
  switchChange() {
    this.getList();
  }
  popoverChange(item) {
    this.popoverForm.min = item.form.min;
    this.popoverForm.max = item.form.max;
  }
  checkCircle(o) {
    if (this.multipleCheck) {
      const idx = this.circleData.findIndex(item => item.cid === o.cid);
      this.circleData[idx].checked = !o.checked;
    }
  }
  submitPopover(o) {
    const idx = this.circleData.findIndex(item => item.cid === o.cid);
    const MIN = Number(this.popoverForm.min);
    const MAX = Number(this.popoverForm.max);
    if (MIN >= MAX) {
      this.$message.error('请保证左侧值小于右侧值');
      return;
    }
    if (!this.multipleCheck) {
      this.circleData[idx].form = {
        min: MIN,
        max: MAX,
      };
      this.circleData[idx].edit = true;
    } else {
      this.circleData.map((item, index) => {
        if (item.checked) {
          this.circleData[index].form = {
            min: MIN,
            max: MAX,
          };
          this.circleData[index].edit = true;
        }
      });
    }
    this.circleData[idx].dialog = false;
    this.circleData.map(item => (item.checked = false));
  }
  async submit() {
    let editData = [];
    if (this.radioChoose === '1') {
      const { min, max } = this.form;
      if (min >= max) {
        this.$message.error('请保证左侧值小于右侧值');
        return false;
      } else if (max > 200 && !this.tipsShow) {
        this.tipsShow = true;
        return;
      } else {
        this.tipsShow = false;
        for (let i = 1; i < 61; i++) {
          editData.push({
            cid: i,
            min: Number(min),
            max: Number(max),
          });
        }
      }
    } else {
      editData = this.circleData.map(item => {
        return {
          cid: item.cid,
          min: Number(item.form.min),
          max: Number(item.form.max),
        };
      });
    }
    const params = {
      idxProjectId: this.deviceItem.projectId,
      projectName: this.deviceItem.projectName,
      idxBuildingId: this.deviceItem.buildId,
      buildingName: this.deviceItem.buildName,
      idxTenantId: this.deviceItem.idxTenantId,
      tenantName: this.deviceItem.idxTenantName,
      data: {
        isAll: this.radioChoose === '1',
        deviceName: this.deviceItem.deviceId,
        settingList: editData,
      },
    };
    if (this.radioChoose === '2') {
      params.data.independentSettingList = [];
      this.circleData.forEach(item => {
        const o = {};
        if (item.edit) {
          o.cid = item.cid;
          o.min = Number(item.form.min);
          o.max = Number(item.form.max);
          params.data.independentSettingList.push(o);
        }
      });
    }
    try {
      await deviceDisplacement(params);
      await this.getList();
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0 20px;
  height: calc(100vh - 301px);
  position: relative;
  overflow: auto;
  .unify {
    display: flex;
    align-items: center;
  }
  .inputWidth {
    width: 50px;
  }
  .numHoverUnchecked:hover {
    .editIcon {
      display: inline-block;
    }
  }
  .numHoverChecked:hover {
    .editIconChecked {
      display: inline-block;
    }
  }
  .circleDiv {
    display: flex;
    flex-wrap: wrap;
    .circleNum {
      width: 10%;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      height: 95px;
      .circleChecked {
        color: white;
        background-color: #faad14;
      }
    }
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 1px solid var(--font-title);
    }
    .editIcon {
      display: none;
      cursor: pointer;
    }
    .editIconHide {
      cursor: pointer;
    }
    .editIconChecked {
      display: none;
      cursor: pointer;
    }
  }
  .btn {
    text-align: right;
  }
}
</style>
