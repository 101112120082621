import request from '../request';

const serviceName = '/custom-application';
const serviceName2 = '/things/applicationTemplate';

export function getByTemplateId(id) {
  return request(`${serviceName}/form/get/template/${id}`, {
    method: 'GET',
    body: {},
  });
}

export function getAppByDataId(params) {
  return request(`${serviceName}/table/data/read`, {
    method: 'POST',
    body: params,
  });
}
/**@name 物联网获取表单ID
 * @param formId*/
export function getFormId(formName) {
  return request(`${serviceName2}/getFormId/${formName}`, {
    method: 'GET',
  });
}
