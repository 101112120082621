import request from '../request';

const propServiceName = '/iot-electric-box/api/ali-things/property';
const serviceName = '/iot-electric-box/api/ali-things/service';
const v2ServiceName = '/iot-electric-box/api/v2';
export function getTerminalStatus(deviceName) {
  return request(`${v2ServiceName}/terminalStatus/${deviceName}`, {
    method: 'GET',
  });
}

export function setTerminalThresholds(params) {
  return request(`${v2ServiceName}/settingTerminalThresholds`, {
    method: 'post',
    body: params,
  });
}
export function setAdminPassword(params) {
  return request(`${v2ServiceName}/device-binds/adminPassword`, {
    method: 'PUT',
    body: params,
  });
}

export function lockSwitchSetting(params) {
  return request(`${serviceName}/lockSwitchTimeSetting`, {
    method: 'post',
    body: params,
  });
}
export function getSubBoxStatus(params) {
  return request(`${propServiceName}/subControlBox`, {
    method: 'GET',
    body: params,
  });
}
export function setSubBoxStatus(params) {
  return request(`${serviceName}/subControlBoxService`, {
    method: 'post',
    body: params,
  });
}
