<template>
  <a-form-model
    class="warnSettingsDiv"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <div class="title" v-if="deviceItem.deviceName">
      <span class="marginLeft5">{{
        $t('electricBox.warn.independentWarning')
      }}</span>
      <span class="gray">
        {{ $t('electricBox.warn.configTip1') }}
      </span>
      <a-icon
        v-if="inputDisabled && deviceItem.operational"
        class="edit"
        @click="inputDisabled = false"
        type="edit"
      />
      <a-button
        v-if="!inputDisabled"
        size="small"
        @click="submitData"
        type="primary"
      >
        {{ $t('msg.confirm') }}
      </a-button>
      <a-button
        v-if="!inputDisabled"
        size="small"
        @click="inputDisabled = true"
        style="margin-left: 10px"
      >
        {{ $t('msg.cancel') }}
      </a-button>
    </div>
    <p v-else>
      {{ $t('electricBox.warn.noticeTip1') }}
    </p>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.overloadEvent') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.overloadTip1') }}</span>
        <span>
          <a-form-model-item label="" prop="overload">
            <a-input-number
              v-model="form.overload"
              placeholder=""
              :disabled="inputDisabled"
              :min="0"
              :step="1"
            /> </a-form-model-item
        ></span>
        <span>{{ $t('electricBox.warn.tip1') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.underloadEvent') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.underloadTip1') }}</span>
        <span>
          <a-form-model-item label="" prop="lostLoad">
            <a-input-number
              v-model="form.lostLoad"
              placeholder=""
              :disabled="inputDisabled"
              :min="0"
              :step="1"
          /></a-form-model-item>
        </span>
        <span>{{ $t('electricBox.warn.tip1') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.tiltEvent') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.tiltEventTip1') }}</span>
        <span>
          <a-form-model-item label="" prop="tilt">
            <a-input-number
              v-model="form.tilt"
              placeholder=""
              :disabled="inputDisabled"
              :min="0"
              :step="1"
            /> </a-form-model-item
        ></span>
        <span>{{ $t('electricBox.warn.tip1') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.totalLoad') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.totalLoadTip1') }}</span>
        <span>
          <a-form-model-item label="" prop="totalLoad">
            <a-input-number
              v-model="form.totalLoad"
              placeholder=""
              :disabled="inputDisabled"
              :min="0"
              :step="1"
            />
          </a-form-model-item>
        </span>
        <span>{{ $t('electricBox.warn.tip1') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.tip2') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.totalElectricity') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.totalElecTip1') }}</span>
        <span>
          <a-form-model-item label="" prop="totalLoad">
            <a-input-number
              v-model="form.totalCurrent"
              placeholder=""
              :disabled="inputDisabled"
              :min="0"
              :step="1"
            />
          </a-form-model-item>
        </span>
        <span>{{ $t('electricBox.warn.tip1') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.totalElec') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.connection') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>
          <a-switch v-model="form.connection" :disabled="inputDisabled"
        /></span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.conDesc') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" v-if="!this.deviceItem.deviceName">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.weatherEvents') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span> <a-switch v-model="isSetWeather"/></span>
      </a-col>
    </a-row>
    <a-row
      type="flex"
      justify="start"
      style="margin-top:0 ;"
      v-if="isSetWeather"
    >
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="19" class="rightColumn">
        <span>{{ $t('generalView.table.time') }}：</span>
        <a-select
          :placeholder="$t('iotScreenManage.pleaseSelect')"
          v-model="form.weatherWarnTime"
          style="width: 173px;margin-right: 10px;"
        >
          <a-select-option
            v-for="item in dateArr"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span>{{ $t('electricBox.warn.wind') }}：</span>
        <a-form-model-item label="" prop="windpower">
          <a-select
            v-model="form.windpower"
            style="width: 173px;margin-right: 10px;"
            :placeholder="$t('iotScreenManage.pleaseSelect')"
          >
            <a-select-option
              v-for="item in windArr"
              :key="item + $t('electricBox.warn.level')"
              :value="item"
            >
              {{ item }}{{ $t('electricBox.warn.aboveLevel') }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row
      type="flex"
      justify="start"
      style="margin-top:10px;"
      v-if="isSetWeather"
    >
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="19" class="rightColumn">
        <span>{{ $t('electricBox.warn.weather') }}：</span>
        <a-form-model-item label="" prop="weather">
          <a-select
            mode="multiple"
            v-model="form.weather"
            :placeholder="$t('iotScreenManage.pleaseSelect')"
            style="width: 400px;"
          >
            <a-select-option
              v-for="(item, index) in weatherArr"
              :key="item + index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.LocationWarning') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>
          <a-switch v-model="form.location" :disabled="inputDisabled"
        /></span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.LocationDesc') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <x-member-select
          v-model="form.locationMembers"
          :view="view"
          :value="locationRange"
          @input="updateLocationRangeValue"
          :type="['org-user', 'role-user', 'user', 'external']"
          :limit="20"
          :multiple="true"
        />
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col :span="4" class="leftColumn">
        {{ $t('electricBox.warn.CappingWarning') }}：
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>
          <a-switch v-model="form.closedTop" :disabled="inputDisabled"
        /></span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <span>{{ $t('electricBox.warn.CappingDesc') }}</span>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start" style="margin-top:0 ;">
      <a-col :span="4" class="leftColumn"> </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="18" class="rightColumn">
        <x-member-select
          v-model="form.closedTopMembers"
          :view="view"
          :value="cappingRange"
          @input="updateCappingRangeValue"
          :type="['org-user', 'role-user', 'user', 'external']"
          :limit="20"
          :multiple="true"
        />
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" v-if="!deviceItem.deviceName">
      <div>
        <a-checkbox v-model="isAllUpdate" class="allUpdate">{{
          $t('electricBox.warn.bottomTip')
        }}</a-checkbox>
      </div>
    </a-row>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getWarningsRules,
  configureAlerts,
} from '@/services/iot-electric-box/warn-threshold';
import { deepClone } from '@triascloud/utils';
@Component({})
export default class WarnSettingsForm extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  deviceItem;
  isAllUpdate = false;
  inputDisabled = false;
  form = {
    deviceName: '',
    lostLoad: 0,
    overload: 0,
    tilt: 0,
    totalCurrent: 0,
    totalLoad: 0,
    type: '',
    weather: [],
    weatherWarnTime: undefined,
    windpower: undefined,
    connection: false,
    location: false,
    closedTop: false,
    locationMembers: [],
    closedTopMembers: [],
    id: '',
  };
  locationRange = [];
  updateLocationRangeValue(data) {
    this.locationRange = data[0];
  }
  cappingRange = [];
  updateCappingRangeValue(data) {
    this.cappingRange = data[0];
  }
  view = false;
  rules = {
    weatherWarnTime: [
      {
        required: true,
        message: ' ',
      },
    ],
    windpower: [
      {
        required: true,
        message: ' ',
      },
    ],
    lostLoad: [
      {
        required: true,
        validator: this.checkInput,
        message: ' ',
      },
    ],
    overload: [
      {
        required: true,
        validator: this.checkInput,
        message: ' ',
      },
    ],
    tilt: [{ validator: this.checkInput, required: true, message: ' ' }],
    totalCurrent: [
      { validator: this.checkInput, required: true, message: ' ' },
    ],
    totalLoad: [{ validator: this.checkInput, required: true, message: ' ' }],
    weather: [
      {
        type: 'array',
        required: true,
        message: ' ',
        trigger: 'change',
      },
    ],
  };
  isSetWeather = false;
  dateArr = [
    {
      name: '当天',
      value: 'TODAY',
    },
    {
      name: '未来一天',
      value: 'TOMORROW',
    },
    {
      name: '未来两天',
      value: 'DAY_AFTER_TOMORROW',
    },
  ];
  windArr = ['4', '5', '6', '7', '8', '9', '10', '11', '12'];
  weatherArr = [
    '中度霾',
    '重度霾',
    '严重霾',
    '阵雨',
    '雷阵雨',
    '雷阵雨并伴有冰雹',
    '小雨',
    '中雨',
    '大雨',
    '暴雨',
    '大暴雨',
    '特大暴雨',
    '强阵雨',
    '强雷阵雨',
    '极端降雨',
    '毛毛雨/细雨',
    '小雨-中雨',
    '中雨-大雨',
    '大雨-暴雨',
    '暴雨-大暴雨',
    '大暴雨-特大暴雨',
    '雨雪天气',
    '雨夹雪',
    '阵雨夹雪',
    '冻雨',
    '阵雪',
    '小雪',
    '中雪',
    '大雪',
    '暴雪',
    '小雪-中雪',
    '中雪-大雪',
    '大雪-暴雪',
    '浮尘',
    '扬沙',
    '沙尘暴',
    '强沙尘暴',
    '龙卷风',
    '浓雾',
    '强浓雾',
    '轻雾',
    '大雾',
    '特强浓雾',
  ];
  checkInput(rule, value, callback) {
    if (rule.field === 'tilt') {
      if (value > 2000 || value < 0) {
        callback(new Error(' '));
      } else {
        callback();
      }
    } else if (isNaN(value)) {
      callback(new Error(' '));
    } else if (value > 100 || value < 0) {
      callback(new Error(' '));
    } else {
      callback();
    }
  }
  async mounted() {
    this.inputDisabled = !!this.deviceItem.deviceName;
    let res = await getWarningsRules(this.deviceItem.deviceName);
    if (res.closedTop) {
      res.closedTopMembers = res.closedTopMembers.map(v => {
        return {
          value: v.pkId,
          text: v.memberName,
          avatar: v.avatar,
        };
      });
    }
    if (res.location) {
      res.locationMembers = res.locationMembers.map(v => {
        return {
          value: v.pkId,
          text: v.memberName,
          avatar: v.avatar,
        };
      });
    }
    this.form = res;
    if (this.deviceItem.deviceName) {
      this.isSetWeather = false;
      this.form.deviceName = this.deviceItem.deviceName;
      this.form.type = 'DEVICE';
    } else {
      this.form.type = 'TENANT';
      this.isSetWeather = !!res.weatherWarnTime;
      this.form.windpower = res.windpower || undefined;
      this.form.weatherWarnTime = res.weatherWarnTime || undefined;
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let params = deepClone(this.form);
          if (!this.isSetWeather) {
            params.weatherWarnTime = null;
            params.windpower = '';
            params.weather = [];
          }
          if (params.type === 'TENANT') {
            params.flag = this.isAllUpdate;
          }
          if (!params.location) {
            delete params.location;
            params.locationMembers = [];
          } else {
            params.locationMembers = this.form.locationMembers.map(
              v => v.value,
            );
          }
          if (!params.closedTop) {
            delete params.closedTop;
            params.closedTopMembers = [];
          } else {
            params.closedTopMembers = this.form.closedTopMembers.map(
              v => v.value,
            );
          }

          await configureAlerts(params);

          if (this.deviceItem.deviceName) {
            this.inputDisabled = true;
          }
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }
  async submitData() {
    await this.getValue();
  }
}
</script>

<style lang="less" scoped>
.warnSettingsDiv {
  padding-bottom: 70px;
  .title {
    margin: 10px 0 10px 36px;
    border-left: 4px solid var(--primary);
    padding-left: 6px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  .gray {
    color: var(--btn-border-gray);
    font-size: var(--font-xs);
    margin: 5px 10px;
  }
  .edit {
    margin-left: 10px;
    font-size: 16px;
    color: var(--primary);
  }
  .save {
    font-size: 16px;
    color: #52c41a;
    margin: 0 10px;
  }
  .cancel {
    font-size: 16px;
    color: red;
  }
  .ant-row-flex {
    margin-top: 10px;
  }
  .leftColumn {
    text-align: right;
    line-height: 32px;
  }
  .rightColumn {
    display: flex;
    line-height: 32px;
    span {
      display: inline-block;
      .ant-input {
        width: 55px;
      }
    }
  }
  .allUpdate {
    color: var(--font-sub);
    font-size: var(--font-xs);
  }
}
</style>
