<template>
  <div>
    <div>
      {{ `注意：将会对选中的${lenght}台设备下发消息` }}
    </div>
    <div>
      <a-form-model layout="vertical" :model="form" ref="form" :rules="rules">
        <a-form-model-item label="通知方式" prop="noticeWay">
          <a-radio-group v-model="form.noticeWay">
            <a-radio v-for="(item, key) in wayOptions" :key="key" :value="key">
              {{ item }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.noticeWay === 'SMS_AND_SYSTEM'"
          label="通知类型"
          prop="noticeType"
        >
          <a-radio-group v-model="form.noticeType" @change="changeNoticeType">
            <a-radio v-for="(item, key) in typeOptions" :key="key" :value="key">
              {{ item }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.noticeWay === 'SMS_AND_SYSTEM'"
          label="通知内容"
          prop="noticeContent1"
        >
          <a-textarea
            disabled
            v-model="form.noticeContent1"
            :auto-size="{ minRows: 3, maxRows: 3 }"
          />
        </a-form-model-item>
        <a-form-model-item v-else label="通知内容" prop="noticeContent2">
          <a-textarea
            v-model="form.noticeContent2"
            :auto-size="{ minRows: 3, maxRows: 6 }"
            placeholder="请输入批量通知的内容，限200字"
          />
        </a-form-model-item>
        <div v-if="form.noticeWay === 'SYSTEM'">
          {{ form.noticeContent2.length }}/200
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { deviceNotification } from '@/services/iot-electric-box/terminal-manage';

@Component({})
export default class BatchInform extends Vue {
  @Prop({ type: Array, default: () => [] }) informItems;

  wayOptions = {
    SMS_AND_SYSTEM: '短信+系统通知',
    SYSTEM: '系统通知',
  };
  typeOptions = {
    SAFETY: '安全隐患',
    MAINTAIN: '维护安排',
    UPGRADE: '修复升级',
  };
  form = {
    noticeWay: 'SMS_AND_SYSTEM',
    noticeType: 'SAFETY',
    noticeContent1:
      '【三叠云】您的{项目名称}的{楼栋号}楼栋有安全隐患，请注意及时检查跟进！',
    noticeContent2: '',
  };
  changeNoticeType(v) {
    this.form.noticeContent1 = `【三叠云】您的{项目名称}的{楼栋号}楼栋有${
      this.typeOptions[v.target.value]
    }，请注意及时检查跟进！`;
  }
  rules = {
    noticeContent2: [
      { required: true, message: '请输入通知内容' },
      { max: 200, message: '200字符以内' },
    ],
  };
  get lenght() {
    return this.informItems.length;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const params = {
            buildIds: this.informIds,
            noticeType: this.form.noticeType,
            warningNoticeType: this.form.noticeWay,
            deviceInfos: this.informItems,
          };
          if (this.form.noticeWay === 'SMS_AND_SYSTEM') {
            params.content = this.form.noticeContent1;
          } else {
            params.content = this.form.noticeContent2;
          }
          try {
            await deviceNotification(params);
            this.$message.success('通知成功');
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style scoped></style>
