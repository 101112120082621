<template>
  <div :class="$style.listWrap">
    <!--    松紧钩配置-->
    <div :class="$style.title">
      <span :class="$style.marginLeft5">{{
        $t('electricBox.set.th.thresholds')
      }}</span>
      <span :class="$style.gray"
        >（{{ $t('electricBox.set.th.thDesc') }}）</span
      >
      <a-popconfirm
        :visible="tForm.visible"
        title="仅在设备处于在线状态时方可修改阈值！"
        @confirm="tForm.visible = false"
        @cancel="tForm.visible = false"
      >
        <a-icon
          v-if="tForm.disabled && deviceItem.operational"
          :class="$style.edit"
          @click="editTForm"
          type="edit"
        />
      </a-popconfirm>
      <a-button
        v-if="!tForm.disabled"
        size="small"
        @click="setThresholds"
        type="primary"
      >
        确定
      </a-button>
      <a-button
        v-if="!tForm.disabled"
        size="small"
        @click="resetTForm"
        style="margin-left: 10px"
      >
        取消
      </a-button>
    </div>
    <div style="margin-left: 40px">
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">荷载超载值</span>
        <a-input-number
          v-model="tForm.hookOverloadThreshold"
          :disabled="tForm.disabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议5吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">荷载失载值</span>
        <a-input-number
          v-model="tForm.hookLoadLossThreshold"
          :disabled="tForm.disabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议0吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">松钩范围值</span>
        <a-input-number
          v-model="tForm.stationaryThreshold"
          :disabled="tForm.disabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议0.1吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">紧钩范围值</span>
        <a-input-number
          v-model="tForm.pullingUpThreshold"
          :disabled="tForm.disabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议1.5吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">倾斜范围值</span>
        <a-input-number
          v-model="tForm.frontAndRearTiltAngleThreshold"
          :disabled="tForm.disabled"
          :min="0"
          :max="100"
          :step="1"
        />
        <span :class="$style.gray">毫米 (限0-100，建议60毫米)</span>
      </div>
      <div>
        <span style="margin-right: 10px">附墙高度值</span>
        <a-input-number
          v-model="tForm.wallHeight"
          :disabled="tForm.disabled"
          :min="0.1"
          :max="10"
          :step="0.1"
        />
        <span :class="$style.gray">米 (限0.1-10，建议3米)</span>
      </div>
    </div>
    <!--    设备锁定-->
    <div :class="$style.title">
      <span :class="$style.marginLeft5">{{
        $t('electricBox.set.th.lockDevice')
      }}</span>
      <span :class="$style.gray">
        （{{ $t('electricBox.set.th.ldDesc') }}）
      </span>
      <a-popconfirm
        :visible="lockForm.visible"
        :title="lockForm.title"
        @confirm="lockForm.visible = false"
        @cancel="lockForm.visible = false"
      >
        <a-icon
          v-if="lockForm.disabled && deviceItem.operational"
          :class="$style.edit"
          @click="exidLock"
          type="edit"
        />
      </a-popconfirm>
      <a-button
        v-if="!lockForm.disabled"
        size="small"
        @click="saveLock"
        type="primary"
      >
        确定
      </a-button>
      <a-button
        v-if="!lockForm.disabled"
        size="small"
        @click="resetLockForm"
        style="margin-left: 10px"
      >
        取消
      </a-button>
    </div>
    <div style="margin-left: 40px">
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">{{
          $t('electricBox.set.th.lockingStatus')
        }}</span>
        <a-switch
          :disabled="lockForm.disabled"
          v-model="lockForm.isLock"
          checked-children="是"
          un-checked-children="否"
          :default-checked="true"
        />
      </div>
      <div v-if="lockForm.isLock && !lockForm.unlockCode" style="color:red;">
        注意:开启后所有账号(含admin)均被锁定，若设备正在操作将在本次结束后执行，请谨慎操作！
      </div>
      <div
        v-if="
          deviceItem.deviceStatus !== 'ONLINE' &&
            lockForm.unlockCode &&
            lockForm.isLock &&
            deviceItem.operationalStatus !== 'SYSTEM_LOCKED'
        "
      >
        <span>解锁码：</span>
        <span>{{ showLockCode ? lockForm.unlockCode : '********' }}</span>

        <x-icon
          v-if="showLockCode"
          type="tc-icon-copy"
          :class="$style.copy"
          @click.stop="copyLockCode"
        />
        <x-icon
          v-else
          type="eye"
          :class="$style.copy"
          @click.stop="showLockCode = true"
        />
      </div>
    </div>
    <!--    admin密码-->
    <div :class="$style.title">
      <span :class="$style.marginLeft5">{{
        $t('electricBox.set.th.adminPwd')
      }}</span>
      <span :class="$style.gray">
        （{{ $t('electricBox.set.th.pwdDesc') }}）
      </span>
      <a-popconfirm
        :visible="pwdForm.visible"
        title="仅在设备处于在线状态时方可修改admin密码！"
        @confirm="pwdForm.visible = false"
        @cancel="pwdForm.visible = false"
      >
        <a-icon
          v-if="pwdForm.disabled && deviceItem.operational"
          :class="$style.edit"
          @click="editPassword"
          type="edit"
        />
      </a-popconfirm>
      <a-button
        v-if="!pwdForm.disabled"
        size="small"
        @click="savePassword"
        type="primary"
      >
        确定
      </a-button>
      <a-button
        v-if="!pwdForm.disabled"
        size="small"
        @click="resetPwdForm"
        style="margin-left: 10px"
      >
        取消
      </a-button>
    </div>
    <div style="margin-left: 40px">
      <div style="margin-bottom: 10px">
        <a-form-model
          layout="inline"
          :model="pwdForm"
          :rules="pwdFormRules"
          ref="pwdForm"
        >
          <a-form-model-item
            :label="$t('electricBox.set.th.currentPassword')"
            prop="password"
          >
            <a-input
              v-model="pwdForm.password"
              :disabled="pwdForm.disabled"
              type="text"
              style="width:240px"
            />
            <x-icon
              v-if="deviceItem.operational"
              type="tc-icon-copy"
              :class="$style.copy"
              @click.stop="copy"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <!--    设备有效期-->
    <div :class="$style.title">
      <span :class="$style.marginLeft5">{{
        $t('electricBox.set.th.deviceExpiration')
      }}</span>
      <span :class="$style.gray">
        （{{ $t('electricBox.set.th.deDesc') }}）
      </span>
      <!-- <a-icon
        v-if="timeForm.disabled"
        :class="$style.edit"
        @click="timeForm.disabled = false"
        type="edit"
      /> -->
      <a-button
        v-if="!timeForm.disabled"
        size="small"
        @click="saveTime"
        type="primary"
      >
        确定
      </a-button>
      <a-button
        v-if="!timeForm.disabled"
        size="small"
        @click="timeForm.disabled = true"
        style="margin-left: 10px"
      >
        取消
      </a-button>
    </div>
    <div style="margin-left: 40px">
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">{{
          $t('electricBox.set.th.exp')
        }}</span>
        <a-date-picker
          v-model="timeForm.startTime"
          :disabled-date="date => disabledStartDate(date, 'timeForm')"
          disabled
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="开始时间"
          @openChange="open => handleStartOpenChange(open, 'time')"
        />
        <span style="margin: 0 10px">至</span>
        <a-date-picker
          v-model="timeForm.endTime"
          :disabled-date="disabledEndDate"
          :disabled="timeForm.disabled"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="结束时间"
          :open="endOpen"
          @openChange="open => handleEndOpenChange(open, 'time')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  // devicePassword,
  getLock,
  // getPassword,
  // getExpiredDate,
  // settingValidTime,
} from '@/services/iot-electric-box/terminal-manage';
import {
  getTerminalStatus,
  lockSwitchSetting,
  setTerminalThresholds,
  setAdminPassword,
} from '@/services/iot-electric-box/ali-things';
import { clipboardWrite } from '@triascloud/utils';

@Component({})
export default class MainBoxConfig extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceItem;
  showLockCode = false;
  tForm = {
    disabled: true,
    visible: false,
    deviceName: '',
    frontAndRearTiltAngleThreshold: 0,
    hookLoadLossThreshold: 0,
    hookOverloadThreshold: 0,
    pullingUpThreshold: 0,
    stationaryThreshold: 0,
    wallHeight: 0,
  };
  lockForm = {
    visible: false,
    disabled: true,
    isLock: false,
    unlockCode: '',
    title: '',
  };
  pwdForm = {
    visible: false,
    disabled: true,
    password: '666666',
  };
  pwdFormRules = {
    password: [
      { required: true, message: '请输入密码' },
      { validator: this.checkPassword, trigger: ['change', 'blur'] },
    ],
  };
  checkPassword(rule, value, callback) {
    const regNum = /^[0-9]{6,12}$/.test(value);
    if (!regNum) {
      callback(new Error('请输入6-12位数字'));
    } else {
      callback();
    }
  }
  timeForm = {
    disabled: true,
    startTime: this.$moment(),
    endTime: this.$moment().add(1, 'months'),
  };
  mounted() {
    this.initData();
  }
  initData() {
    const lockPromise = this.getLocks();
    const thresholdsPromise = this.getThresholds();
    const promiseArr = [lockPromise, thresholdsPromise];
    Promise.all(promiseArr).then(res => {
      if (res.length > 0) {
        this.$message.info('待设备上报更新数据！');
      }
    });
  }
  get paramsCommon() {
    return {
      idxProjectId: this.deviceItem.projectId,
      projectName: this.deviceItem.projectName,
      idxBuildingId: this.deviceItem.buildId,
      buildingName: this.deviceItem.buildName,
      idxTenantId: this.deviceItem.idxTenantId,
      tenantName: this.deviceItem.idxTenantName,
    };
  }
  getLocks() {
    const params = {
      ...this.paramsCommon,
      deviceName: this.deviceItem.deviceName,
    };
    return new Promise((resolve, reject) => {
      getLock(params)
        .then(result => {
          if (result) {
            this.lockRes = result;
            this.lockForm.isLock = result.flag === 1;
            if (result.flag === 1) {
              this.lockForm.unlockCode = result.unlockCode;
            }
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          reject(false);
          return false;
        });
    });
  }
  editTForm() {
    if (this.deviceItem.deviceStatus === 'ONLINE') {
      this.tForm.disabled = false;
    } else {
      this.tForm.visible = true;
    }
  }
  resetTForm() {
    let res = this.originData;
    this.tForm.disabled = true;
    for (let key in this.tForm) {
      if (res[key] !== undefined) {
        this.tForm[key] = res[key];
      }
    }
  }
  resetPwdForm() {
    this.pwdForm.disabled = true;
    this.pwdForm.password =
      this.originData.adminPassWord === -1
        ? '666666'
        : this.originData.adminPassWord;
  }
  resetLockForm() {
    this.lockForm.disabled = true;
    this.lockForm.isLock = this.lockRes.flag === 1;
  }
  resetTimeForm() {
    let res = this.originData;
    this.timeForm.disabled = true;
    this.timeForm.startTime = this.formatTimeToMoment(
      this.$moment(res.startTime).valueOf(),
    );
    this.timeForm.endTime = this.formatTimeToMoment(
      this.$moment(res.endTime).valueOf(),
    );
  }
  async getThresholds() {
    return new Promise((resolve, reject) => {
      getTerminalStatus(this.deviceItem.deviceName)
        .then(res => {
          if (res) {
            this.originData = res;
            for (let key in this.tForm) {
              if (res[key] !== undefined) {
                this.tForm[key] = res[key];
              }
            }

            this.timeForm.startTime = this.formatTimeToMoment(
              this.$moment(res.startTime).valueOf(),
            );
            this.timeForm.endTime = this.formatTimeToMoment(
              this.$moment(res.endTime).valueOf(),
            );
            if (res.adminPassWord !== -1) {
              this.pwdForm.password = res.adminPassWord;
            }
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          reject(false);
          return false;
        });
    });
  }
  async setThresholds() {
    this.tForm.deviceName = this.deviceItem.deviceName;
    await setTerminalThresholds(this.tForm);
    this.tForm.disabled = true;
    this.$message.success('保存成功！');
  }
  formatTimeToString(t) {
    const time = Number(t);
    if (t === '' || t === undefined || t === null) return '';
    return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  formatTimeToMoment(t) {
    const time = Number(t);
    if (t === '' || t === undefined || t === null) return;
    const tStr = this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
    return this.$moment(tStr, 'YYYY-MM-DD HH:mm:ss');
  }
  disabledStartDate(startValue, form) {
    const endValue = this[form].endTime;
    const now = this.$moment().valueOf();
    if (!startValue || !endValue) {
      return false;
    }
    const flag1 = startValue.valueOf() < now;
    const flag2 = startValue.valueOf() > endValue.valueOf();
    return form === 'lockForm' ? flag1 || flag2 : flag2;
  }
  disabledEndDate(endValue) {
    const startValue = this.timeForm.startTime;
    const time = [];
    time.push(startValue);
    if (!endValue || !startValue) {
      return false;
    }
    const flag1 = endValue.valueOf() <= time[0].valueOf() + 2592000000;
    const flag2 = endValue.valueOf() >= 2147483647000; // 2038-01-19 11:14:07
    return flag1 || flag2;
  }
  lockEndOpen = false;
  endOpen = false;
  handleStartOpenChange(open, flag) {
    if (!open) {
      flag === 'lock' ? (this.lockEndOpen = true) : (this.endOpen = true);
    }
  }
  handleEndOpenChange(open, flag) {
    flag === 'lock' ? (this.lockEndOpen = open) : (this.endOpen = open);
  }
  exidLock() {
    if (this.deviceItem.deviceStatus === 'ONLINE') {
      if (this.deviceItem.operationalStatus === 'SYSTEM_LOCKED') {
        this.lockForm.title =
          '设备已被系统管理员锁定，请联系您的设备供应商解锁！';
        this.lockForm.visible = true;
      } else {
        this.lockForm.disabled = false;
      }
    } else {
      if (this.lockForm.isLock) {
        this.lockForm.title =
          '离线状态下无法远程解锁，建议在设备端手工输入解锁码解除！';
      } else {
        this.lockForm.title = '离线状态下无法远程锁定、解锁！';
      }
      this.lockForm.visible = true;
    }
  }
  async saveLock() {
    const params = {
      ...this.paramsCommon,
      data: {
        deviceName: this.deviceItem.deviceName,
      },
    };
    if (!this.lockForm.isLock) {
      params.data.flag = 0;
    } else {
      params.data.flag = 1;
    }
    try {
      let res = await lockSwitchSetting(params);
      this.lockForm.isLock = res.flag === 1;
      this.lockForm.unlockCode = res.unlockCode;
    } catch {
      return false;
    } finally {
      this.lockForm.disabled = true;
    }
  }
  editPassword() {
    if (this.deviceItem.deviceStatus !== 'ONLINE') {
      this.pwdForm.visible = true;
    } else {
      this.pwdForm.disabled = false;
    }
  }
  async savePassword() {
    const params = {
      deviceName: this.deviceItem.deviceName,
      adminPassWord: this.pwdForm.password,
    };
    try {
      await setAdminPassword(params);
    } catch {
      return false;
    } finally {
      this.pwdForm.disabled = true;
    }
  }
  // async saveTime() {
  //   const params = {
  //     deviceName: this.deviceItem.deviceName,
  //     expireTime: this.timeForm.endTime.valueOf().toString(),
  //   };
  //   try {
  //     await settingValidTime(params);
  //   } catch {
  //     return false;
  //   } finally {
  //     this.timeForm.disabled = true;
  //   }
  // }
  async copy() {
    const str = `admin密码：${this.pwdForm.password}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
  async copyLockCode() {
    const str = '解锁码：' + this.lockForm.unlockCode;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0 20px;
  height: calc(100vh - 330px);
  position: relative;
  overflow: auto;
  .marginLeft5 {
    margin-left: 5px;
  }
  .title {
    margin: 10px 16px;
    border-left: 4px solid var(--primary);
    height: 24px;
    display: flex;
    align-items: center;
  }
  .gray {
    color: var(--btn-border-gray);
  }
  .edit {
    margin-left: 10px;
    font-size: 16px;
    color: var(--primary);
  }
  .save {
    font-size: 16px;
    color: #52c41a;
    margin: 0 10px;
  }
  .cancel {
    font-size: 16px;
    color: red;
  }
  .copy {
    margin-left: 10px;
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
