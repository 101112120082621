<template>
  <div :class="$style.container">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <div :class="$style.version" v-if="infoList.length > 0">
      {{ $t('electricBox.mg.versionNumber') }}：
      <div v-for="(item, key, idx) in versionList" :key="idx">
        <a-tag :color="item">
          <span :class="$style.tagColor">
            {{ key === 'unknown' ? '未知' : key }}
          </span>
        </a-tag>
      </div>
    </div>
    <div v-if="infoList.length > 0" :class="$style.circle">
      <div v-for="item in infoList" :key="item.cid">
        <a-tooltip
          v-if="item.updateTime !== ''"
          :placement="
            [11, 22, 33, 44, 55].indexOf(item.cid) > -1
              ? 'bottomRight'
              : 'bottom'
          "
        >
          <template slot="title">
            <span>{{
              formateTime(
                item.updateTime,
                `${$t('electricBox.mg.updateTime')}：`,
              )
            }}</span>
          </template>
          <div
            :class="$style.circleItem"
            :style="{ backgroundColor: versionList[item.subVersion] }"
          >
            <div>{{ item.cid }}</div>
          </div>
        </a-tooltip>
        <div
          v-else
          :class="$style.circleItem"
          :style="{ backgroundColor: versionList[item.subVersion] }"
        >
          {{ item.cid }}
        </div>
      </div>
    </div>
    <div v-else :class="$style.circle">
      <Empty />
    </div>

    <div :class="$style.mainBoxVersion" v-if="infoList.length > 0">
      <span>{{ $t('electricBox.mg.mainBoxVersion') }}：{{ version }}</span>
      <span v-if="updateTime !== ''" :class="$style.time">
        （{{ $t('electricBox.mg.updateTime') }}：{{ formateTime(updateTime) }}）
      </span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getHardwareInfo } from '@/services/iot-electric-box/terminal-manage';
import dayjs from 'dayjs';
import Empty from '@/components/empty-content';
import ConditionSearch from '@/components/condition-search';

@Component({
  components: {
    Empty,
    ConditionSearch,
  },
})
export default class HardWare extends Vue {
  @Prop({ type: String, default: '' }) deviceName;

  options = [
    {
      label: this.$t('electricBox.mg.firmwareType'),
      type: 3,
      model: 'subFirmwareEnum',
      items: [
        {
          selected: true,
          label: '分控板固件',
          value: 'SUB_CONTROL_BOARD',
        },
        {
          label: '分控屏幕固件',
          value: 'SUB_CONTROL_SCREEN',
        },
      ],
      width: '220px',
    },
    {
      label: this.$t('iotScreenManage.area'),
      type: 3,
      model: 'area',
      items: [
        {
          label: '全部',
          value: 'all',
          selected: true,
        },
        {
          label: '区域1',
          value: '1',
        },
        {
          label: '区域2',
          value: '2',
        },
        {
          label: '区域3',
          value: '3',
        },
        {
          label: '区域4',
          value: '4',
        },
        {
          label: '区域5',
          value: '5',
        },
        {
          label: '区域6',
          value: '6',
        },
      ],
      width: '200px',
    },
  ];
  form = {
    area: 'all',
    subFirmwareEnum: 'SUB_CONTROL_BOARD',
  };
  handleSearchSubmit(data) {
    for (let key in data) {
      this.form[key] = data[key];
    }
    this.getList();
  }
  handleReset() {}
  colorArr = [
    '#9CE1AD',
    '#6DD1D1',
    '#C8A7FF',
    '#80adff',
    '#A6C5FF',
    '#947dfa',
    '#00b7c3',
    '#FFDD9B',
    '#F9A7A7',
    '#F4F4F4',
  ];

  mounted() {
    this.getList();
  }
  version = '';
  updateTime = '';
  versionList = {};
  infoList = [];
  async getList() {
    this.versionList = {};
    const params = {
      deviceName: this.deviceName,
      area: this.form.area !== 'all' ? `AREA${this.form.area}` : undefined,
      subFirmwareEnum: this.form.subFirmwareEnum,
    };
    try {
      const res = await getHardwareInfo(params);
      if (res.subVersions.length > 0) {
        this.version = res.version;
        this.updateTime = res.updatedTime;
        const idx = res.subVersions.findIndex(v => v === 'unknown');
        if (idx > -1) {
          const a = res.subVersions.splice(idx, 1);
          res.subVersions.push(a[0]);
        }
        res.subVersions.forEach((v, idx) => {
          this.versionList[v] =
            v !== 'unknown' ? this.colorArr[idx] : '#F4F4F4';
        });
        this.infoList = res.subFirmwareInfos;
      } else {
        this.versionList = {};
        this.infoList = [];
      }
    } catch {
      return false;
    }
  }
  formateTime(time, text = '') {
    return time !== ''
      ? `${text}${dayjs(time).format('YYYY-MM-DD HH:mm')}`
      : '';
  }
}
</script>

<style module lang="less">
.container {
  height: 100%;
  position: relative;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 15px;
  margin-top: -16px;
  :global {
    .ant-tabs {
      min-height: 400px;
    }
  }
  .time {
    color: var(--font-info);
  }
  .version {
    display: flex;
    align-items: center;
    height: 35px;
    color: var(--font-sub);
    font-size: var(--font-sm);
    padding-left: 6px;
    .tagColor {
      color: #333;
    }
  }
  .circle {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .circleItem {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
      margin: 5px;
    }
  }
  .mainBoxVersion {
    padding: 30px 0 0 6px;
    color: var(--font-sub);
    font-size: var(--font-sm);
  }
}
</style>
