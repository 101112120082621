<template>
  <a-form-model
    class="addCameraBoxwrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item
      :label="$t('electricBox.form.deviceName')"
      prop="deviceName"
    >
      <a-input
        :placeholder="
          $t('electricBox.form.enter', {
            value: $t('electricBox.form.deviceName'),
          })
        "
        :disabled="!canEdit"
        v-model="form.deviceName"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('electricBox.form.deviceSecret')"
      prop="deviceSecret"
    >
      <a-input
        :placeholder="
          $t('electricBox.form.enter', {
            value: $t('electricBox.form.deviceSecret'),
          })
        "
        :disabled="!canEdit"
        v-model="form.deviceSecret"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('electricBox.table.projectName')"
      prop="projectId"
    >
      <a-select
        show-search
        allowClear
        v-model="form.projectId"
        :placeholder="$t('electricBox.select.projects')"
        :disabled="statusDisabled"
        @change="handleProjectChange"
        option-filter-prop="children"
        :filter-option="false"
        @search="input => filterProjectOption(input)"
      >
        <a-select-option
          :value="item.id"
          v-for="item in projects"
          :key="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('electricBox.form.buildingNumber')"
      prop="buildId"
    >
      <a-select
        v-model="form.buildId"
        :placeholder="$t('electricBox.form.buildingDesc')"
        show-search
        allowClear
        :disabled="statusDisabled"
        option-filter-prop="children"
        :filter-option="filterOption"
        @popupScroll="handleScroll"
      >
        <a-select-option
          :value="item.id"
          v-for="item in buildings"
          :key="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getBuildingByProject } from '@/services/things/building';
import { getRelatedProject } from '@/services/things/project.js';

import { bindDevice } from '@/services/iot-electric-box/terminal-manage';
import { Debounce } from 'lodash-decorators';

@Component({})
export default class BindDevice extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;

  form = {
    deviceName: '',
    deviceSecret: '',
    idxTenantId: undefined,
    projectId: undefined,
    buildId: undefined,
  };
  rules = {
    deviceName: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('electricBox.form.deviceName'),
        }),
      },
    ],
    deviceSecret: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('electricBox.form.deviceSecret'),
        }),
      },
    ],
    idxTenantId: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('electricBox.form.company'),
        }),
      },
    ],
  };
  canEdit = false;
  tenantSearchInput = '';
  projectSearchInput = '';
  @Debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input.toLowerCase();
    this.getProjectData();
  }
  @Debounce(300)
  filterTenantOption(input) {
    this.tenantSearchInput = input.toLowerCase();
    this.getEnterpriseList();
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  cameraStatus = '';
  get statusDisabled() {
    return ['ONLINE', 'OFFLINE'].indexOf(this.cameraStatus) > -1;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    const data = this.editData;
    this.canEdit = !data.deviceName;
    this.form.deviceName = data.deviceName;
    this.form.deviceSecret = data.deviceSecret;
    this.form.idxTenantId = data.idxTenantId || undefined;
    this.tenantSearchInput = data.tenantName;
    this.projectSearchInput = data.projectName;
    this.form.projectId = data.projectId || undefined;
    await this.handleProjectChange(this.form.projectId);
    this.form.buildId = data.buildId || undefined;
    this.getProjectData();
  }
  projects = [];
  buildings = [];
  async handleProjectChange(id) {
    this.form.projectId = id;
    this.buildings = [];
    this.form.buildId = undefined;
    if (!id) {
      this.projectSearchInput = '';
      this.getProjectData();
    } else {
      this.getBuildingData();
    }
  }
  async getProjectData() {
    let projectParams = {
      'tenantId': this.form.idxTenantId,
      'query.size': 100,
    };
    if (this.projectSearchInput) {
      projectParams.keyword = this.projectSearchInput;
    }
    projectParams = {};
    if (this.projectSearchInput) {
      projectParams.keyword = this.projectSearchInput;
    }
    let records = await getRelatedProject(projectParams);
    if (!projectParams.keyword) {
      records = records.splice(0, 100);
    }
    records = records.map(item => {
      return {
        id: item.projectId,
        name: item.projectName,
      };
    });
    this.projects = records;
  }
  buildingTotal = 0;
  buildingCurrent = 1;
  async getBuildingData() {
    const buildParams = {
      'projectId': this.form.projectId,
      'query.current': this.buildingCurrent,
    };
    const { records, total } = await getBuildingByProject(buildParams);
    this.buildingTotal = total;
    const list = records.map(item => {
      return {
        id: item.pkBuildingId,
        name: item.buildingName,
      };
    });
    this.buildings = this.buildings.concat(list);
  }
  handleScroll(e) {
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;
    const { height } = e.target.getBoundingClientRect();
    if (scrollHeight - height - scrollTop < 10) {
      if (this.buildingTotal > this.buildingCurrent * 10) {
        this.buildingCurrent++;
        this.getBuildingData();
      }
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const params = {
              buildId: '',
              projectId: '',
              deviceName: this.form.deviceName,
              tenantId: this.form.idxTenantId,
              validateCode: this.form.deviceSecret,
              operatingDeviceInfo: window.navigator.userAgent,
            };
            if (this.form.projectId) {
              params.projectId = this.form.projectId;
            }
            if (this.form.buildId) {
              params.buildId = this.form.buildId;
            }
            params['operatingType'] = this.canEdit ? 'ADD' : 'BIND';
            await bindDevice(params);
            this.$message.success(
              this.$t('electricBox.form.bindingSuccessful'),
            );
            resolve(true);
          } catch (error) {
            if (error.code === 10018) {
              resolve(true);
            } else {
              reject(false);
            }
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" scoped></style>
