<template>
  <div :class="$style.listWrap">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :locale="emptyLocale"
      @change="handlePagination"
    >
      <template slot="scoreTitle">
        <span>{{ $t('generalView.table.rating') }}</span>
        <a-icon
          @click="openScoreTips"
          :class="$style.scoreIcon"
          type="question-circle"
        />
      </template>
      <template slot="operationDate" slot-scope="text">
        {{ formatTime(text) }}
      </template>
      <template slot="action" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="shareReport(record)">分享</a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import shareForm from './share-form.vue';
import { listReport } from '@/services/iot-electric-box/terminal-manage';
import { createFormModal, createModal } from '@triascloud/x-components';
import shareBox from '../../../iot-screen-manage/share-box.vue';
import ScoreTips from '@/views/iot/general-view/safe-operation/score-tips.vue';
@Component({
  components: {
    ConditionSearch,
    shareForm,
  },
})
export default class LiftReport extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  @Prop({ type: String, default: '' }) deviceName;
  text = '开发中';
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  params = {
    pageSize: 10,
    pageNum: 1,
    deviceName: '',
    buildingId: '',
    endTime: '',
    startTime: '',
  };
  tableData = [];
  options = [
    {
      label: this.$t('generalView.table.time'),
      model: ['startTime', 'endTime'],
      type: 5,
    },
  ];
  created() {
    this.params.deviceName = this.deviceName;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.getList();
  }
  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.buildingId = this.deviceInfo.buildingId;
    this.pagination.current = 1;
    await this.getList();
  }
  mounted() {}
  async getList() {
    if (!this.params.buildingId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    if (!this.params.reportType) {
      delete this.params.reportType;
    }
    let res = await listReport(this.params);
    this.pagination.total = res.total;
    this.tableData = (res && res.records) || [];
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        width: 300,
        align: 'left',
        title: this.$t('hat.electronFence.name'),
        dataIndex: 'reportType',
        customRender: (val, item) => {
          return (
            '操作报告（' +
            (item.autoReport ? '自动' : '手动') +
            '）--' +
            this.$moment(item.reportDate).format('YYYYMMDD')
          );
        },
      },
      {
        width: 200,
        align: 'left',
        title: this.$t('electricBox.mg.table.createTime'),
        dataIndex: 'operationDate',
        scopedSlots: { customRender: 'operationDate' },
      },
      {
        align: 'left',
        slots: { title: 'scoreTitle' },
        dataIndex: 'score',
      },
      {
        width: 80,
        title: this.$t('iotScreenManage.operation'),
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  openScoreTips() {
    createModal(() => <ScoreTips />, {
      title: '报告评分规则',
      width: 500,
    });
  }
  handleSearchSubmit(data) {
    for (let key in data) {
      if (data[key]) {
        this.params[key] = data[key];
      } else {
        delete this.params[key];
      }
    }
    this.pagination.current = 1;
    this.getList();
  }
  handleReset() {}
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
  async shareReport(item) {
    let title =
      '操作报告（' +
      (item.autoReport ? '自动' : '手动') +
      '）-' +
      this.$moment(item.reportDate).format('YYYYMMDD');

    const data = {
      reportId: item.pkId,
      projectName: title,
    };
    const shareInfo = await createFormModal(() => <shareForm data={data} />, {
      title: '楼栋提升报告',
      okText: '生成链接',
      className: this.$style.shareModalForm,
      width: `500px`,
    });
    shareInfo.isReport = true;
    const modal = createModal(
      () => (
        <shareBox shareInfo={shareInfo} onClose={() => modal.handleClose()} />
      ),
      {
        title: '楼栋提升报告',
        className: this.$style.shareModal,
        width: `500px`,
      },
    );
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0;
  position: relative;
  .scoreIcon {
    cursor: pointer;
    margin-left: 5px;
    color: var(--primary);
  }
  :global {
    .searchBtns {
      text-align: right;
    }
  }
}
</style>
