<template>
  <div :class="$style.listWrap">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :locale="emptyLocale"
      @change="handlePagination"
    >
      <template slot="createDate" slot-scope="text">
        {{ formatTime(text) }}
      </template>
      <template slot="eventContent" slot-scope="contents">
        <div v-for="(text, index) in contents" :key="index">{{ text }}</div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import {
  typeOfConfig,
  listConfigLog,
} from '@/services/iot-electric-box/terminal-manage';
@Component({
  components: {
    ConditionSearch,
  },
})
export default class ConfigLog extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  @Prop({ type: String, default: '' }) deviceName;

  text = '开发中';
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  params = {
    pageSize: 10,
    pageNum: 1,
    deviceName: '',
    tenantId: '',
    fromTime: '',
    toTime: '',
    actionCode: '',
    idxBuildingId: '',
    idxProjectId: '',
    idxTenantId: '',
  };
  typeList = [];
  tableData = [];
  options = [
    {
      label: this.$t('electricBox.mg.table.settingType'),
      type: 3,
      model: 'actionCode',
      items: [
        {
          label: this.$t('iotScreenManage.all'),
          value: '',
        },
      ],
      width: '250px',
    },
    {
      label: this.$t('generalView.table.time'),
      model: ['fromTime', 'toTime'],
      type: 5,
    },
  ];
  created() {
    this.getConfigType();
    this.handleBuildingIdChange();
  }
  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.idxBuildingId = this.deviceInfo.buildingId;
    this.params.idxProjectId = this.deviceInfo.projectId;
    this.params.idxTenantId = this.deviceInfo.tenantId;
    this.params.deviceName = this.deviceName;
    this.pagination.current = 1;
    await this.getList();
  }
  async getConfigType() {
    this.typeList = await typeOfConfig();
    for (let item of this.typeList) {
      this.options[0].items.push({
        label: item.descriptionOfAction,
        value: item.actionCode,
      });
    }
  }
  mounted() {}
  async getList() {
    if (!this.params.idxBuildingId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    let param = JSON.parse(JSON.stringify(this.params));
    if (param.toTime) {
      param.toTime = this.changeTime(param.toTime);
      param.fromTime = this.changeTime(param.fromTime);
    }
    let res = await listConfigLog(param);
    for (let item of res.records) {
      item.content = item.content.split('\r\n');
    }
    this.tableData = res.records;
    this.pagination.total = res.total;
  }
  changeTime(str) {
    return str
      ? this.$moment(str)
          .format('YYYY-MM-DD HH:mm:ss')
          .replace(/ /g, 'T') + 'Z'
      : '';
  }
  formatTime(str) {
    return str ? str.replace(/T/, ' ').replace(/Z/, '') : ''; //this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        width: 160,
        align: 'left',
        title: this.$t('electricBox.mg.table.settingType'),
        dataIndex: 'actionCode',
        customRender: val => {
          return this.typeList.find(item => {
            return item.actionCode === val;
          }).descriptionOfAction;
        },
      },
      {
        width: 120,
        align: 'left',
        title: this.$t('electricBox.mg.table.settingPerson'),
        dataIndex: 'creatorName',
      },
      {
        width: 180,
        align: 'left',
        title: this.$t('generalView.table.time'),
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createDate' },
      },
      {
        title: this.$t('electricBox.mg.eventContent'),
        align: 'left',
        dataIndex: 'content',
        scopedSlots: { customRender: 'eventContent' },
      },
    ];
  }
  handleSearchSubmit(data) {
    for (let key in data) {
      this.params[key] = data[key];
    }
    this.pagination.current = 1;
    this.getList();
  }
  handleReset() {}
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0;
  position: relative;

  :global {
    .searchBtns {
      text-align: right;
    }
  }
}
</style>
