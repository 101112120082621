<template>
  <div :class="$style.detail">
    <div :class="$style.info">
      <a-row :class="$style.infoItem">
        <a-col>
          <div :class="$style.textHidden">
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.project') }}：</span
            >
            <span :title="configData.projectName">
              {{ configData.projectName }}
            </span>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" :class="$style.infoItem">
        <a-col :span="12">
          <div :class="$style.textHidden">
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.building') }}：</span
            >
            <span :title="configData.buildName">
              {{ configData.buildName }}
            </span>
          </div>
        </a-col>
        <a-col :span="12">
          <div :class="$style.textHidden">
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.company') }}：</span
            >
            <span :title="configData.idxTenantName">
              {{ configData.idxTenantName }}
            </span>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" :class="$style.infoItem">
        <a-col :span="12">
          <div>
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.deviceCode') }}：</span
            >
            <span :title="configData.deviceName">
              {{ configData.deviceName }}
            </span>
          </div>
        </a-col>
        <a-col :span="12">
          <div>
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.status') }}：</span
            >
            <span>{{ formatStatus(configData) }}</span>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" :class="$style.infoItem">
        <a-col :span="12">
          <div>
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.latestOnline') }}：</span
            >
            <span :title="configData.deviceName">
              {{ formatTime(configData.lastOnlineTime) }}
            </span>
          </div>
        </a-col>
        <a-col :span="12">
          <div>
            <span :class="$style.text"
              >{{ $t('electricBox.set.head.exp') }}：</span
            >
            <span>{{ formatTime(configData.expireDate) }}</span>
          </div>
        </a-col>
      </a-row>
    </div>
    <div :class="$style.contentBox">
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="1" :tab="$t('electricBox.set.terminalSetting')">
          <MainBoxConfig v-if="activeKey === '1'" :deviceItem="configData" />
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('electricBox.set.sub-boxSetting')">
          <SubBoxConfig v-if="activeKey === '2'" :deviceItem="configData" />
        </a-tab-pane>
        <a-tab-pane key="4" :tab="$t('electricBox.set.liftedDetails')">
          <Promote
            v-if="activeKey === '4'"
            :buildingId="configData.buildId"
            :netStatus="configData.operationalStatus"
            :deviceItem="configData"
          />
        </a-tab-pane>
        <a-tab-pane key="5" :tab="$t('electricBox.set.rulesSettings')">
          <WarnSettingsForm
            v-if="activeKey === '5'"
            :deviceItem="configData"
          ></WarnSettingsForm>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Load from './load';
import Displacement from './displacement';
import MainBoxConfig from './main-config';
import SubBoxConfig from './sub-config';
import Promote from './promote';
import WarnSettingsForm from '../warn-settings-form';

@Component({
  components: {
    Load,
    Displacement,
    MainBoxConfig,
    Promote,
    SubBoxConfig,
    WarnSettingsForm,
  },
})
export default class DeviceConfiguration extends Vue {
  @Prop({ type: Function, default: function() {} }) close;
  @Prop({ type: String, default: '' }) defaultActivityKey;
  @Prop({ type: Object, default: () => {} }) configData;

  formatTime(t) {
    if (t) {
      return this.$moment(t).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  activeKey = '-1';
  @Watch('defaultActivityKey', { immediate: true })
  keyChange(newVal) {
    this.activeKey = newVal;
  }
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formatStatus(o) {
    const statusObj = {
      ONLINE: this.$t('electricBox.list.online'),
      OFFLINE: this.$t('electricBox.list.offline'),
      UN_ASSIGNED: this.$t('electricBox.list.unAssigned'),
      UN_BIND: this.$t('electricBox.list.unbind'),
      BIND: this.$t('electricBox.list.bind'),
      LOCKED: this.$t('electricBox.list.lock'),
    };
    return statusObj[o.operationalStatus];
  }
}
</script>

<style lang="less" module>
.detail {
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 75px);

  .info {
    margin: 20px 20px 0;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);

    .infoItem {
      padding: 5px 0;
    }
  }

  .text {
    color: var(--font-title);
  }
  .textHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contentBox {
    .boxTitle {
      font-size: 14px;
      color: var(--font);
      padding: 0 20px 10px;
    }
  }
}
</style>
