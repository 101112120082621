import request from '../request';

const serviceName = '/iot-electric-box/threshold';

export function configureAlerts(params) {
  return request(`${serviceName}/configureAlerts`, {
    method: 'put',
    body: params,
  });
}

export function getWarningsRules(deviceName) {
  if (deviceName) {
    return request(`${serviceName}/device/${deviceName}`, {
      method: 'get',
    });
  } else {
    return request(`${serviceName}/tenant`, {
      method: 'get',
    });
  }
}
