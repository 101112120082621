<template>
  <div :class="$style.listWrap">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :locale="emptyLocale"
      @change="handlePagination"
    >
      <template slot="operationTime" slot-scope="text">
        {{ formatTime(text) }}
      </template>
      <template slot="action" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="openForm(record)">查看</a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import { listApproval } from '@/services/iot-electric-box/terminal-manage';
import { getFormId, getAppByDataId } from '@/services/iot-electric-box/form';

@Component({
  components: {
    ConditionSearch,
  },
})
export default class OperateAuth extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  params = {
    pageSize: 10,
    pageNum: 1,
    deviceId: '',
    buildingId: '',
    tenantId: '',
    endTime: '',
    startTime: '',
    reportType: 'OPERATION_AUTHORITY',
  };
  tableData = [];
  options = [
    {
      label: this.$t('electricBox.mg.table.type'),
      type: 3,
      model: 'reportType',
      items: [
        {
          label: this.$t('electricBox.mg.operationAuth'),
          value: 'OPERATION_AUTHORITY',
          selected: true,
        },
        {
          label: this.$t('electricBox.mg.safetyDisclosure'),
          value: 'SAFE_CONFESSION',
        },
        {
          label: this.$t('electricBox.mg.check'),
          value: 'SELF_CHECK_AFTER_OPERATION',
        },
      ],
      width: '170px',
    },
    {
      label: this.$t('generalView.table.time'),
      model: ['startTime', 'endTime'],
      type: 5,
    },
  ];
  created() {
    this.params.deviceId = this.deviceInfo.deviceId;
    this.params.tenantId = this.deviceInfo.tenantId;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.getList();
  }
  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.tenantId = this.deviceInfo.tenantId;
    this.params.buildingId = this.deviceInfo.buildingId;
    this.pagination.current = 1;
    await this.getList();
  }
  mounted() {}
  async getList() {
    if (!this.params.buildingId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    if (!this.params.reportType) {
      delete this.params.reportType;
    }
    let res = await listApproval(this.params);
    this.pagination.total = res.total;
    this.tableData = (res && res.records) || [];
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('electricBox.mg.table.type'),
        dataIndex: 'reportType',
        width: 120,
        customRender: val => {
          return {
            OPERATION_AUTHORITY: this.$t('electricBox.mg.operationAuth'),
            SAFE_CONFESSION: this.$t('electricBox.mg.safetyDisclosure'),
            SELF_CHECK_AFTER_OPERATION: this.$t('electricBox.mg.check'),
          }[val];
        },
      },
      {
        align: 'left',
        title: this.$t('electricBox.mg.table.submitTime'),
        width: 180,
        dataIndex: 'operationTime',
        scopedSlots: { customRender: 'operationTime' },
      },
      {
        align: 'left',
        title: this.$t('electricBox.mg.table.submitPeople'),
        dataIndex: 'operationMember',
      },
      {
        title: this.$t('iotScreenManage.operation'),
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  handleSearchSubmit(data) {
    for (let key in data) {
      if (data[key]) {
        this.params[key] = data[key];
      } else {
        delete this.params[key];
      }
    }
    this.pagination.current = 1;
    this.getList();
  }
  handleReset() {
    // debugger;
    // this.params.reportType = 'OPERATION_AUTHORITY';
  }
  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }
  async openForm(record) {
    let formId = await getFormId('boa');
    // let formId = await getByTemplateId('1353597847310401537');
    let res = await getAppByDataId({
      activityId: '',
      authId: '',
      dataId: record.dataId,
      formId,
      isSendAgain: false,
    });
    const prefix =
      process.env.VUE_APP_ENV === 'production'
        ? ''
        : process.env.VUE_APP_ENV + '.';
    window.open(
      `http://${prefix}app.triascloud.com/application/${res.form.appId}/form/${formId}?dataId=${record.dataId}`,
    );
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0;
  position: relative;

  :global {
    .searchBtns {
      text-align: right;
    }
    .ant-form-item {
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}
</style>
