import request from '../request';

const serviceName = '/iot-electric-box/client/device';
const serviceNameThing = '/iot-electric-box/api/ali-things/service';
const serviceNameProperty = '/iot-electric-box/api/ali-things/property';

/**
 * @name 批量注册设备
 */
export function registerDevice(data) {
  return request(`${serviceName}/batchRegisterDevice`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 注册设备产品名称
 */
export function registerDeviceProductName() {
  return request(`${serviceName}/getProductName`, {
    method: 'GET',
    closeLoading: true,
  });
}

/**
 * @name 注册设备产品名称
 */
export function deviceCounts() {
  return request(`${serviceName}/count`, {
    method: 'GET',
    closeLoading: true,
  });
}

/**
 * @name 设备列表
 */
export function getDeviceList(data) {
  return request(`${serviceName}/listDeviceBackend`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 设备列表导出
 */
export function exportDeviceList(data) {
  return request(`${serviceName}/exportList`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 绑定电箱
 */
export function bindDevice(data) {
  return request(`${serviceName}/bindDevice`, {
    method: 'PUT',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 获取载荷配置
 */
export function getDeviceLoad(data) {
  return request(`${serviceNameProperty}/loadSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 设备配置 载荷配置
 */
export function deviceLoad(data) {
  return request(`${serviceNameThing}/loadSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 获取倾斜配置
 */
export function getDeviceDisplacement(data) {
  return request(`${serviceNameProperty}/displacementSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 设备配置 倾斜配置
 */
export function deviceDisplacement(data) {
  return request(`${serviceNameThing}/displacementSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取松紧钩配置
 */
export function getDeviceHooks(data) {
  return request(`${serviceNameProperty}/elasticHookSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 松紧钩配置
 */
export function deviceHooks(data) {
  return request(`${serviceNameThing}/elasticHookSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取操作模式配置
 */
export function getOperation(data) {
  return request(`${serviceNameProperty}/operationModeSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 设备配置 安全配置 操作模式配置
 */
export function deviceOperation(data) {
  return request(`${serviceNameThing}/operationModeSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取附墙高度配置
 */
export function getHeight(data) {
  return request(`${serviceNameProperty}/attachedWallHeightSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 设备配置 安全配置 附墙高度配置
 */
export function deviceHeight(data) {
  return request(`${serviceNameThing}/attachedWallHeightSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取锁定配置,此接口已取消
 */
export function getLock(data) {
  return request(`${serviceNameThing}/lockSwitch`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 设备配置 安全配置 锁定配置
 */
export function deviceLock(data) {
  return request(`${serviceNameThing}/lockSwitchTimeSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取密码配置
 */
export function getPassword(data) {
  return request(`${serviceNameProperty}/adminPasswordSetting`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 设备配置 安全配置 密码配置
 */
export function devicePassword(data) {
  return request(`${serviceNameThing}/adminPasswordSetting`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取过期时间
 */
export function getExpiredDate(data) {
  return request(`${serviceNameProperty}/privilegedTime`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/**
 * @name 设备配置 安全配置 过期时间
 */
export function deviceExpiredDate(data) {
  return request(`${serviceNameThing}/privilegedTime`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取电箱操作日志
 */
export function getOperationLog(data) {
  return request(`${serviceName}/deviceLog`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 解绑电箱
 */
export function unBindDevice(data) {
  return request(`${serviceName}/unBindDevice`, {
    method: 'PUT',
    closeLoading: true,
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}
/**
 * @name 删除电箱
 */
export function deleteDevice(data) {
  return request(`${serviceName}/deleteDevice`, {
    method: 'DELETE',
    closeLoading: true,
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}
/**
 * @name 设备列表，批量通知
 */
export function deviceNotification(data) {
  return request(`${serviceName}/sendNotification`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取楼栋提升数据
 */
export function getBuildingInfo(id) {
  return request(`/things/climb/climbEnterDetail/${id}`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/**
 * @name 设备配置 安全配置 获取楼栋提升数据
 */
export function setBuildingInfo(data) {
  return request(`/things/climb/climbEnterDetail`, {
    method: 'PUT',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取所有爬架提升记录
 */
export function getAllBuidingInfo(data) {
  return request(`/things/climbMessage/listAll`, {
    method: 'GET',
    closeLoading: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 获取所有爬架提升记录
 */
export function addBuidingInfo(data) {
  return request(`/things/climbMessage/climbMessage`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 获取电箱绑定企业列表
 */
export function getBindTenant(data) {
  return request(`${serviceName}/bindTenant`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取电箱绑定项目列表
 */
export function getBindProject(data) {
  return request(`${serviceName}/bindProject`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取电箱绑定楼栋列表
 */
export function getBindBuilding(data) {
  return request(`${serviceName}/bindBuilding`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取电箱信息
 */
export function getDeviceInfo(data) {
  return request(`${serviceName}/info`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 修改爬架提升记录
 */
export function editBuidingInfo(data) {
  return request(`/things/climbMessage/climbMessage`, {
    method: 'PUT',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 设备配置 安全配置 修改爬架提升记录
 */
export function deleteBuidingInfo(id) {
  return request(`/things/climbMessage/climbMessage/${id}`, {
    method: 'DELETE',
    closeLoading: true,
  });
}

/**
 * @name 获取电箱告警信息
 */
export function listAlarmMessage(data) {
  return request(`${serviceName}/listAlarmMessage`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取操作审批列表
 */
export function listApproval(data) {
  return request(`${serviceName}/listOperationalApproval`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取提升报告列表
 */
export function listReport(data) {
  return request(`${serviceName}/listReport`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取绑定记录列表
 */
export function listBind(data) {
  return request(`${serviceName}/listBind`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取配置日志列表
 */
export function listConfigLog(data) {
  return request(`${serviceName}/configLog`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取配置类型
 */
export function typeOfConfig(data) {
  return request(`${serviceName}/typeOfConfig`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取配置类型
 */
export function safetyRaty(data) {
  return request(`${serviceName}/safetyRaty`, {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 获取通知列表
 */
export function listNoticeRecord(data) {
  return request(`${serviceName}/listNoticeRecord`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}
/**
 * @name 发送通知
 */
export function sendNotification(data) {
  return request(`${serviceName}/sendNotification`, {
    method: 'POST',
    closeLoading: true,
    body: data,
  });
}

/**
 * @name 获取固件信息
 */
export function getHardwareInfo(data) {
  return request('/iot-electric-box/api/v2/getFirmwareInfo', {
    method: 'GET',
    closeLoading: true,
    body: data,
  });
}
