<template>
  <div :class="$style.container">
    <div class="title">{{ $t('electricBox.mg.noticeTitle') }}</div>
    <div :class="$style.description">
      ({{ $t('electricBox.mg.noticeDesc') }})
    </div>
    <Empty v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox"></div>
    <div :class="$style.tableBar">
      <span>{{ $t('electricBox.mg.noticeReport') }}</span>
      <!-- <a-button @click="addNotice" type="primary" icon="plus">通知</a-button> -->
    </div>
    <div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.id"
        :locale="emptyLocale"
        @change="handlePagination"
      >
        <template slot="createTime" slot-scope="text">
          {{ formatTime(text) }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  safetyRaty,
  listNoticeRecord,
} from '@/services/iot-electric-box/terminal-manage';
import resize from '@/utils/resize';
import Empty from '@/components/empty-content';
import * as echarts from 'echarts';
@Component({
  mixins: [resize],
})
export default class DeviceNotice extends Vue {
  @Prop({ type: String, default: 'DeviceNotice' }) id;
  @Prop({ type: Object, default: () => {} }) deviceInfo;
  @Prop({ type: Object, default: () => {} }) deviceData;
  @Prop({ type: String, default: '' }) deviceName;
  chart = '';
  lineData = [];
  tableData = [];
  params = {
    buildId: '',
    deviceName: '',
    pageNum: 1,
    pageSize: 10,
  };
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  };
  async created() {
    this.params.buildId = this.deviceInfo.buildingId;
    this.params.deviceName = this.deviceName;
    this.getTableData();
    await this.getLineData();
    this.initChart();
  }
  mounted() {}
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  @Watch('deviceInfo.buildingId')
  async handleBuildingIdChange() {
    this.params.buildId = this.deviceInfo.buildingId;
    this.pagination.current = 1;
    this.getLineData();
    this.getTableData();
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm');
    } else {
      return '';
    }
  }
  get columns() {
    return [
      {
        align: 'left',
        width: 180,
        title: this.$t('electricBox.mg.table.noticeTime'),
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
      },
      {
        align: 'left',
        title: this.$t('electricBox.mg.table.noticePerson'),
        width: 150,
        dataIndex: 'creatorName',
      },
      {
        title: this.$t('electricBox.mg.table.noticeCtx'),
        align: 'left',
        dataIndex: 'content',
        ellipsis: true,
      },
    ];
  }

  handlePagination(data) {
    this.pagination = data;
    this.getTableData();
  }
  async getLineData() {
    if (!this.params.buildId) {
      this.lineData = [];
      return;
    }
    this.lineData = await safetyRaty(this.deviceInfo);
  }
  async getTableData() {
    if (!this.params.buildId) {
      this.tableData = [];
      return;
    }
    this.params.pageSize = this.pagination.pageSize;
    this.params.pageNum = this.pagination.current;
    let res = await listNoticeRecord(this.params);
    this.tableData = res.records;
    this.pagination.total = res.total;
  }
  get isNoData() {
    return false;
    // if (!this.data || !this.data.length) {
    //   if (this.chart) {
    //     this.chart.dispose();
    //     this.chart = null;
    //   }
    //   return true;
    // } else {
    //   this.$nextTick(this.initChart);
    //   return false;
    // }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = this.lineOptions(this.lineData);
    this.chart.setOption(options);
  }

  lineOptions(data) {
    // data = [
    //   { time: 1632452735605, safeIndex: 12 },
    //   { time: 1634453723605, safeIndex: 42 },
    //   { time: 1635454765605, safeIndex: 52 },
    // ];
    const categorys = [];
    const values = [];
    for (let item of data) {
      categorys.push(this.$moment(item.time).format('MM月DD日'));
      values.push(item.safeIndex);
    }
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff', // 通过设置rgba调节背景颜色与透明度
        color: '#333',
        borderWidth: '1',
        padding: 10,
        borderColor: '#fff',
        textStyle: {
          color: '#333',
        },
      },

      legend: {
        show: false,
      },
      grid: {
        top: '20',
        left: '5%',
        borderWidth: 1,
        right: '0',
        bottom: '10%',
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: categorys,
        axisLabel: {
          fontSize: 14,
        },
      },
      yAxis: {
        // min: 0,
        // max: 100,
        show: true,
        splitLine: {
          show: true,
          lineStyle: {
            show: true,
            color: ['#eee'],
            type: 'dashed',
          },
        },
        type: 'value',
        axisTick: { show: false },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#999999',
          },
        },
        axisLabel: {
          fontSize: 12,
        },
      },
      series: [
        {
          name: '安全指数波动',
          type: 'line',
          smooth: true,
          data: values,
          itemStyle: {
            normal: {
              color: '#5585F7',
              lineStyle: {
                color: '#5585F7',
                width: 3,
              },
            },
          },
        },
      ],
    };
  }
}
</script>

<style module lang="less">
.container {
  height: 100%;
  position: relative;
  font-size: 16px;
  color: #333;
  padding-left: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  .description {
    color: #999;
  }
  .tableBar {
    padding: 20px 0 10px;
    display: flex;
    justify-content: space-between;
  }
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
  height: 250px;
}
</style>
