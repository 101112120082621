<template>
  <div :class="$style.listWrap">
    <Empty v-if="boxArr && boxArr.length === 0" />
    <!--    松紧钩配置-->
    <div :class="$style.title" v-if="boxArr.length > 0">
      <span :class="$style.marginLeft5">{{
        $t('electricBox.set.sc.sub-boxSetting')
      }}</span>
      <span :class="$style.gray">
        <a-select
          placeholder="请选择"
          v-model="currentBox"
          @change="currentBoxChanged"
          style="width: 120px;margin: 10px,10px,-10px;"
        >
          <a-select-option
            v-for="(item, index) in boxArr"
            :key="item.cid + index"
            :value="index"
          >
            {{ item.cid }}
          </a-select-option>
        </a-select>
      </span>
      <a-popconfirm
        :visible="tipsVisible"
        title="仅在设备处于在线状态时方可修改阈值！"
        @confirm="tipsVisible = false"
        @cancel="tipsVisible = false"
      >
        <a-icon
          v-if="inputDisabled && deviceItem.operational"
          :class="$style.edit"
          @click="enableInput"
          type="edit"
        />
      </a-popconfirm>
      <a-button
        v-if="!inputDisabled"
        size="small"
        @click="setSubBox"
        type="primary"
      >
        确定
      </a-button>
      <a-button
        v-if="!inputDisabled"
        size="small"
        @click="cancelInput"
        style="margin-left: 10px"
      >
        取消
      </a-button>
    </div>
    <div v-if="boxArr.length > 0" style="margin-left: 40px">
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">荷载超载值</span>
        <a-input-number
          v-model="tForm.hookOverloadThreshold"
          :disabled="inputDisabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议5吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">荷载失载值</span>
        <a-input-number
          v-model="tForm.hookLoadLossThreshold"
          :disabled="inputDisabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议0吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">松钩范围值</span>
        <a-input-number
          v-model="tForm.stationaryThreshold"
          :disabled="inputDisabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议0.1吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">紧钩范围值</span>
        <a-input-number
          v-model="tForm.pullingUpThreshold"
          :disabled="inputDisabled"
          :min="0"
          :max="7.5"
          :step="0.1"
        />
        <span :class="$style.gray">吨 (限0-7.5吨，建议1.5吨)</span>
      </div>
      <div style="margin-bottom: 10px">
        <span style="margin-right: 10px">倾斜范围值</span>
        <a-input-number
          v-model="tForm.slopeRangeValue"
          :disabled="inputDisabled"
          :min="0"
          :max="100"
          :step="1"
        />
        <span :class="$style.gray">毫米 (限0-100，建议60毫米)</span>
      </div>
      <div>
        <span style="margin-right: 10px">附墙高度值</span>
        <a-input-number
          v-model="tForm.wallHeight"
          :disabled="inputDisabled"
          :min="0.1"
          :max="10"
          :step="0.1"
        />
        <span :class="$style.gray">米 (限0.1-10，建议3米)</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getSubBoxStatus,
  setSubBoxStatus,
} from '@/services/iot-electric-box/ali-things';
import { deepClone } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
import Empty from '@/components/empty-content';

@Component({
  components: { Empty },
})
export default class SubBoxConfig extends Vue {
  @Prop({ type: Object, default: () => {} }) deviceItem;
  tForm = {};
  currentBox = undefined;
  prevBox = 0;
  inputDisabled = true;
  deviceName = '';
  tipsVisible = false;
  boxArr = [];
  oBoxArr = [];
  mounted() {
    this.initData();
  }
  initData() {
    this.getSubBox();
  }
  async currentBoxChanged(val) {
    let isModified = false;
    for (let key in this.oBoxArr[val]) {
      if (this.oBoxArr[this.prevBox][key] !== this.boxArr[this.prevBox][key]) {
        isModified = true;
      }
    }
    if (!this.inputDisabled && isModified) {
      await createFormModal(() => <span>修改尚未保存，是否继续切换?</span>, {
        width: '442px',
        title: '操作提示',
        okText: '确定',
        onOk: () => {
          this.resetInput(this.prevBox);
          this.prevBox = val;
          this.$set(this, 'tForm', this.boxArr[val]);
          this.inputDisabled = true;
        },
        onCancel: () => {
          this.currentBox = this.prevBox;
        },
      });
    } else {
      this.prevBox = val;
      this.$set(this, 'tForm', this.boxArr[val]);
    }
  }
  get paramsCommon() {
    return {
      idxProjectId: this.deviceItem.projectId,
      projectName: this.deviceItem.projectName,
      idxBuildingId: this.deviceItem.buildId,
      buildingName: this.deviceItem.buildName,
      idxTenantId: this.deviceItem.idxTenantId,
      tenantName: this.deviceItem.idxTenantName,
    };
  }
  enableInput() {
    if (this.deviceItem.deviceStatus !== 'ONLINE') {
      this.tipsVisible = true;
      return;
    }
    this.inputDisabled = false;
  }
  async getSubBox() {
    let res = await getSubBoxStatus({
      deviceName: this.deviceItem.deviceName,
    });
    for (let item of res) {
      for (let key in item) {
        if (!isNaN(item[key]) && item[key] < 0) {
          item[key] = 0;
        }
      }
    }
    this.boxArr = res;
    this.oBoxArr = deepClone(this.boxArr);
    if (this.boxArr.length) {
      this.currentBox = 0;
      this.$set(this, 'tForm', this.boxArr[0]);
    }
  }
  async setSubBox() {
    let idx = this.currentBox;
    let params = this.paramsCommon;
    this.tForm.deviceName = this.deviceItem.deviceName;
    params.data = {
      cid: 0,
      deviceName: '',
      slopeRangeValue: 0,
      hookLoadLossThreshold: 0,
      hookOverloadThreshold: 0,
      pullingUpThreshold: 0,
      stationaryThreshold: 0,
      wallHeight: 0,
    };
    for (let key in params.data) {
      params.data[key] = this.tForm[key];
    }
    await setSubBoxStatus(params);
    this.tForm.inputDisabled = true;
    for (let key in this.boxArr[idx]) {
      this.oBoxArr[idx][key] = this.boxArr[idx][key];
    }
    this.inputDisabled = true;
  }
  resetInput(idx) {
    for (let key in this.boxArr[idx]) {
      this.boxArr[idx][key] = this.oBoxArr[idx][key];
    }
  }
  cancelInput() {
    this.resetInput(this.currentBox);
    this.inputDisabled = true;
  }
}
</script>

<style lang="less" module>
.listWrap {
  padding: 0 20px;
  height: calc(100vh - 330px);
  position: relative;
  overflow: auto;
  .marginLeft5 {
    margin-left: 5px;
  }
  .title {
    margin: 20px 16px;
    border-left: 4px solid var(--primary);
    height: 24px;
    display: flex;
    align-items: center;
  }
  .gray {
    color: var(--btn-border-gray);
    margin: 5px 10px;
  }
  .edit {
    margin-left: 10px;
    font-size: 16px;
    color: var(--primary);
  }
  .save {
    font-size: 16px;
    color: #52c41a;
    margin: 0 10px;
  }
  .cancel {
    font-size: 16px;
    color: red;
  }
  .copy {
    margin-left: 10px;
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
